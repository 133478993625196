import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Facilities, Role } from '../../pages/admin/Users/userAdminContext';
import { AiOutlineCheck } from 'react-icons/ai';

interface MultiSelectOption {
  id: number;
  name: string;
  route: string;
}

interface Props {
  selected: MultiSelectOption[] | Facilities[];
  available: MultiSelectOption[] | Facilities[];
  onChange: (list: MultiSelectOption[] | Facilities[]) => void;
}

const Wrapper = styled.div`
  position: relative;
`;

const SelectedWrapper = styled.div`
  padding: 5px 10px;
  height: 40px;
  border: 1px solid ${(props) => props.theme.palette.grey.fg};
  border-radius: 5px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const MultiSelectWindow = styled.div`
  position: absolute;
  top: 42px;
  border: 1px solid ${(props) => props.theme.palette.grey.fg};
  border-radius: 5px;
  width: 100%;
  background: white;
  max-height: 300px;
  overflow-y: auto;
`;

const SelectOption = styled.div`
  padding: 10px 0;
  margin: 0 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.bg};
`;

const SelectName = styled.div``;

const ToggleSelect = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.palette.grey.fg};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MultiSelect: React.FC<Props> = ({ selected, available, onChange }) => {
  const wrapperRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentlySelected, setCurrentlySelected] =
    useState<MultiSelectOption[] | Facilities[]>(selected);

  const openSelected = () => {
    setIsOpen(!isOpen);
  };

  const toggleSelected = (sel: MultiSelectOption | Facilities) => {
    if (currentlySelected.find((f) => f.id === sel.id)) {
      setCurrentlySelected(currentlySelected.filter((f) => f.id !== sel.id));
    } else {
      setCurrentlySelected([...currentlySelected, sel]);
    }
  };

  useEffect(() => {
    onChange(currentlySelected);
  }, [currentlySelected]);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current!.contains(event.target)) {
        setIsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <Wrapper ref={wrapperRef}>
      <SelectedWrapper onClick={() => openSelected()}>
        {currentlySelected &&
          (currentlySelected
            .map((f: Facilities | MultiSelectOption) => f.name)
            .join(', ') as string)}
      </SelectedWrapper>
      {isOpen && (
        <MultiSelectWindow>
          {available.map((f: Facilities | MultiSelectOption) => (
            <SelectOption key={f.id} onClick={() => toggleSelected(f)}>
              <SelectName>{f.name}</SelectName>
              <ToggleSelect>
                {currentlySelected.find((x) => x.id === f.id) && (
                  <AiOutlineCheck />
                )}
              </ToggleSelect>
            </SelectOption>
          ))}
        </MultiSelectWindow>
      )}
    </Wrapper>
  );
};

export default MultiSelect;
