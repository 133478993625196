import Radio from 'components/input/Radio';
import Modal from 'components/Modal';
import useModalStack from 'components/Modal/useModalStack';
import Spinner from 'components/Spinner';
import usePricingEditorReducer, {
  actions,
} from 'containers/PriceProductEditor/usePricingEditorState';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import AIDefaults, {
  GeneralStrategy,
  generateAiDefault,
  tryGetAIDefaultApplicator,
} from 'constants/AIDefaults';
import { useSelector } from 'react-redux';
import { selectCurrentFacility, selectCurrentZone } from 'store/zone/selector';

const generalStategyName = 'generalStrategy';
const segmentStrategyName = 'segmentStrategy';

enum SegmentStrategy {
  driveUp = 'driveUp',
  balanced = 'balanced',
  booking = 'booking',
}

const MyModal = styled(Modal)`
  width: 500px;
`;

const MySpinner = styled(Spinner)`
  margin-left: auto;
  margin-right: auto;
`;

const Section = styled.div`
  margin: 20px 0;
`;

const Question = styled.div`
  font-weight: 700;
  margin-bottom: 10px;
`;

interface Props {
  priceProductEditorReducer: ReturnType<typeof usePricingEditorReducer>;
  zoneId: number;
}

const PriceProductAIModal: FC<Props> = ({
  priceProductEditorReducer,
  zoneId,
}) => {
  const [generalStrategy, setGeneralStrategy] = useState<GeneralStrategy>();
  const [segmentStrategy, setSegmentStrategy] = useState<SegmentStrategy>(
    SegmentStrategy.driveUp
  );
  const [processing, setProcessing] = useState(false);
  const curFacility = useSelector(selectCurrentFacility);
  const curZone = useSelector(selectCurrentZone);

  const modalStack = useModalStack();

  const handleGeneralStratChange = (
    eve: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGeneralStrategy(eve.target.value as GeneralStrategy);
  };

  const handleSegmentStratChange = (
    eve: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSegmentStrategy(eve.target.value as SegmentStrategy);
  };

  const handleProcessClick = () => {
    setProcessing(true);

    setTimeout(() => {
      modalStack.pop();

      // Try getting a "new" applicator
      const applicator = tryGetAIDefaultApplicator(zoneId, generalStrategy!);
      const [, dispatch] = priceProductEditorReducer;

      if (applicator) {
        // If an applicator was gotten we short-circuit and use it
        dispatch(actions.applyAIDefaultsFN(applicator));
        return;
      }

      // Otherwise we fall back to the old ...
      // Regular defaults from the AIDefaults.json file
      var defaults = AIDefaults.filter(
        (d) => d.generalStrategy === generalStrategy && d.CliZoneId === zoneId
      );

      if (defaults.length === 0) {
        // If none was found there, we peek into other data sources (Among Oslo-AI-Pricing.json)
        defaults = generateAiDefault(
          curFacility,
          curZone!,
          generalStrategy!,
          priceProductEditorReducer
        );
      }

      dispatch(actions.applyOldAIDefaults(defaults));
    }, 4000 + Math.random() * 1000);
  };

  if (processing) {
    return (
      <MyModal notClosable title="Processing...">
        <MySpinner />
      </MyModal>
    );
  }

  return (
    <MyModal
      title="Ask the AI"
      buttons={[
        {
          label: 'Find optimal price settings!',
          disabled:
            generalStrategy === undefined || segmentStrategy === undefined,
          dontPopAfterClick: true,
          onClick: handleProcessClick,
        },
      ]}
    >
      <Section>
        <Question>What is your general strategy?</Question>
        <Radio
          name={generalStategyName}
          checked={generalStrategy === GeneralStrategy.volume}
          value={GeneralStrategy.volume}
          onChange={handleGeneralStratChange}
        >
          Volume based
        </Radio>
        <Radio
          name={generalStategyName}
          checked={generalStrategy === GeneralStrategy.balanced}
          value={GeneralStrategy.balanced}
          onChange={handleGeneralStratChange}
        >
          Balanced strategy
        </Radio>
        <Radio
          name={generalStategyName}
          checked={generalStrategy === GeneralStrategy.maximize}
          value={GeneralStrategy.maximize}
          onChange={handleGeneralStratChange}
        >
          Maximize revenue
        </Radio>
      </Section>

      {/*<Section>
        <Question>What is your segment strategy?</Question>
        <Radio
          name={segmentStrategyName}
          checked={segmentStrategy === SegmentStrategy.driveUp}
          value={SegmentStrategy.driveUp}
          onChange={handleSegmentStratChange}
        >
          Drive up focused
        </Radio>
         <Radio
          name={segmentStrategyName}
          checked={segmentStrategy === SegmentStrategy.balanced}
          value={SegmentStrategy.balanced}
          onChange={handleSegmentStratChange}
        >
          Balanced
        </Radio>
        <Radio
          name={segmentStrategyName}
          checked={segmentStrategy === SegmentStrategy.booking}
          value={SegmentStrategy.booking}
          onChange={handleSegmentStratChange}
        >
          Booking focused
        </Radio> 
      </Section>*/}
    </MyModal>
  );
};

export default PriceProductAIModal;
