import { ApplicationState } from 'store/definitions';

export const selectUiStateDuration = (root: ApplicationState) =>
  root.uiState.duration;

export const selectUiStateSegment = (root: ApplicationState) =>
  root.uiState.segments;

// export const selectUiStatePtype = (root: ApplicationState) =>
//   root.uiState.ptype;

const locateSeriesByID = (root: ApplicationState, id: string) => {
  if (!root.calcCache.cached) return undefined;
  for (let z of root.calcCache.cached.data.zones) {
    for (let s of z.series) {
      if (s.outID === id) return s;
    }
  }
  return undefined;
};

export const selectCalcStepLengthMinutes: (root: ApplicationState) => number = (
  root: ApplicationState
) => {
  const storedID = selectUiStatePriceTypeViewID(root, true);
  const viewDuration = root.uiState.duration;
  // calculte step-size for selected duration
  const viewStepDurationInMinutes =
    viewDuration === 'day'
      ? 60 // day -> 1 hour
      : viewDuration === 'week'
      ? parseInt(storedID.split(',').slice(-1)[0]) < 24 * 60
        ? 60
        : 24 * 60
      : viewDuration === 'monthsInYear' || viewDuration === 'weeksInYear'
      ? 7 * 24 * 60 // year -> 1 week.
      : 24 * 60; // month -> 1 day

  return viewStepDurationInMinutes as number;
};

export const selectUIValidPriceTypes = (
  root: ApplicationState,
  noAvg: boolean = false
) => {
  const viewDuration = root.uiState.duration;
  const viewDurationInMinutes =
    viewDuration === 'day'
      ? 24 * 60
      : viewDuration === 'week'
      ? 7 * 24 * 60
      : viewDuration === 'monthsInYear' || viewDuration === 'weeksInYear'
      ? 365 * 24 * 60
      : 28 * 24 * 60; // TODO: compute correct number of days?

  const viewStepDurationInMinutes = noAvg
    ? 1
    : selectCalcStepLengthMinutes(root);

  const cachedPriceSeries = root.calcCache.priceSeries;
  // augument the series
  const cachedPriceSeriesWithSampleDuration = cachedPriceSeries.map((ps) => {
    const sd = locateSeriesByID(root, ps.id);
    const seriesDataDurMin = (sd?.stepInSeconds ?? 3000000000) / 60;
    return {
      ...ps,
      seriesDataDurMin,
      isAverage:
        !(viewDuration === 'week' && seriesDataDurMin <= 24 * 60) &&
        seriesDataDurMin !== viewStepDurationInMinutes,
    };
  });

  return cachedPriceSeriesWithSampleDuration.filter(
    (ser) => ser.defForced || ser.seriesDataDurMin < viewDurationInMinutes
  );
  //.map((ser) => ser.ps);
};

export const selectUiStatePriceTypeViewID = (
  root: ApplicationState,
  noAvg: boolean = false
) => {
  const storedID = root.uiState.priceTypeViewID;

  const pts = selectUIValidPriceTypes(root, noAvg);

  const verified = pts
    //.filter((cps)=>parseInt(cps.id.split(',').slice(-1)[0])  )
    .find((cps) => cps.id === storedID)
    ? storedID
    : ',price,regular,0000060';
  return verified;
};

export const selectUiStateStartDate = (root: ApplicationState) =>
  root.uiState.startDate;

export const selectUiStateEndDate = (root: ApplicationState) =>
  root.uiState.endDate;

export const selectUiStateDataUpdateTS = (root: ApplicationState) =>
  root.uiState.uiDataUpdateTS;

export const selectUIStateRevView = (root: ApplicationState) =>
  root.uiState.revview;
