import { ApplicationState } from '../definitions';
import { selectHistoryFacilityMatch } from '../router/selector';

export function selectCurrentFacility(state: ApplicationState) {
  return state.uiSettings.sites.find(
    (fac) => fac.path === state.uiState.activeFacility
  )!;
}

export function selectCurrentZone(state: ApplicationState) {
  return state.zoneData.find((zn) => zn.id == state.uiState.activeZoneID);
}

export function selectAvailableZones(state: ApplicationState) {
  return state.zoneData;
}

export function selectAvailableFacilityZones(state: ApplicationState) {
  return state.zoneData.filter(
    (f) => f.sitePath == state.uiState.activeFacility
  );
}
