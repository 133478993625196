import React, { useContext, useState } from 'react';

interface ModalStack {
  readonly stack: React.ReactChild[];
  push: (child: React.ReactChild) => void;
  pop: () => void;
}

const ModalStackContext = React.createContext<ModalStack>({
  stack: [],
  push: () => {},
  pop: () => {},
});

export const ModalProvider: React.FC = ({ children }) => {
  const [stack, setStack] = useState<React.ReactChild[]>([]);

  const push = (component: React.ReactChild) => {
    setStack((stack) => [...stack, component]);
  };

  const pop = () => {
    setStack((stack) => {
      const newStack = [...stack];
      newStack.pop();
      return newStack;
    });
  };

  return (
    <ModalStackContext.Provider value={{ stack, push, pop }}>
      {/* {stack.length > 0 && stack[stack.length - 1]} */}
      {stack.map((modal, i) => (
        <React.Fragment key={i}>{modal}</React.Fragment>
      ))}
      {children}
    </ModalStackContext.Provider>
  );
};

const useModalStack = () => {
  const context = useContext(ModalStackContext);
  return context;
};

export default useModalStack;
