import { ApplicationState } from '../definitions';
import { UISettings } from './reducer';

export function getFirstUserFacility(state: ApplicationState) {
  return state.uiSettings.user.facilities[0];
}

export function getUser(state: ApplicationState) {
  return state.uiSettings.user;
}

export function getAdminStatus(state: ApplicationState) {
  return state.uiSettings.hasUserAdmin;
}

export function selectUISettingSites(state: ApplicationState) {
  return state?.uiSettings?.sites;
}
export function selectUISettingZones(state: ApplicationState) {
  return state?.uiSettings?.siteZones;
}
