import Button from 'components/input/Button';
import Input from 'components/input/Input';
import Label from 'components/input/Label';
import List from 'components/List';
import useModalStack from 'components/Modal/useModalStack';
import SubHeader from 'components/SubHeader';
import React, { useContext, useEffect, useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import styled from 'styled-components';
import { User, UserAdminContext } from './userAdminContext';
import UserPopup, {
  ConfirmRemoveUser,
  ErrorPopup,
  SuccessPopup,
} from './userPopup';

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px 40px;
`;

const UserListFilterWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  button {
    margin-bottom: 10px;
    margin-left: 30px;
  }
`;

const UserListWrapper = styled.div``;

const SendActivationWrapper = styled.div`
  max-width: 230px;
`;

const IconWrapper = styled.div`
  max-width: 40px;
  cursor: pointer;
`;

const UserListComponent: React.FC<{}> = () => {
  const {
    users,
    roles,
    facilities,
    createUser,
    updateUser,
    removeUser,
    sendActivationMail,
  } = useContext(UserAdminContext);
  const modalStack = useModalStack();
  const [isEditUser, setIsEditUser] = useState<User | undefined>(undefined);
  const [filter, setFilter] = useState<string>('');
  const [isAddUser, setIsAddUser] = useState<boolean>(false);

  const editUser = (user: User) => {
    setIsEditUser(user);
  };
  useEffect(() => {
    if (isEditUser) {
      modalStack.push(
        <UserPopup
          isEdit={true}
          user={isEditUser}
          facilities={facilities}
          roles={roles}
          onClose={() => {
            setIsEditUser(undefined);
          }}
          onUpdate={updateUser}
        />
      );
    } else {
      modalStack.pop();
    }
  }, [isEditUser]);

  useEffect(() => {
    if (isAddUser) {
      modalStack.push(
        <UserPopup
          facilities={facilities}
          roles={roles}
          isEdit={false}
          onClose={() => {
            setIsAddUser(false);
          }}
          onCreate={createUser}
        />
      );
    } else {
      modalStack.pop();
    }
  }, [isAddUser]);

  return (
    <Content>
      <UserListFilterWrapper>
        <Label label={'Search user'}>
          <Input
            value={filter}
            onChange={(e: any) => setFilter(e.target.value)}
          />
        </Label>
        <Button
          onClick={() => {
            setIsAddUser(true);
          }}
        >
          Add user
        </Button>
      </UserListFilterWrapper>
      <UserListWrapper>
        <SubHeader buttons={<></>}>{'Active users'}</SubHeader>
        <List<User>
          columnSettings={[
            {
              header: 'Email',
              minWidth: '300px',
              display: ({ row }) => {
                return <div>{row.email}</div>;
              },
            },
            {
              header: 'Facilities',
              minWidth: '400px',
              display: ({ row }) => {
                return (
                  <div>{row.facilities.map((f) => f.name).join(', ')}</div>
                );
              },
            },
            {
              header: 'Role',
              minWidth: '100px',
              display: ({ row }) => {
                return <div>{row.roles[0].name}</div>;
              },
            },
            {
              header: '',
              flexGrow: 0,
              minWidth: '30px',
              display: ({ row }) => {
                return (
                  <IconWrapper
                    onClick={() => {
                      editUser(row);
                    }}
                  >
                    <FaEdit />
                  </IconWrapper>
                );
              },
            },
            {
              header: '',
              flexGrow: 0,
              minWidth: '30px',
              display: ({ row }) => {
                return (
                  <IconWrapper
                    onClick={() => {
                      modalStack.push(
                        <ConfirmRemoveUser
                          email={row.email}
                          onSuccess={() => {
                            modalStack.pop();
                            removeUser!(row.id!, (success: boolean) => {
                              var timer = setTimeout(() => {
                                modalStack.pop();
                              }, 5000);
                              if (success) {
                                modalStack.push(
                                  <SuccessPopup
                                    onClose={() => {
                                      modalStack.pop();
                                      clearTimeout(timer);
                                    }}
                                    message={
                                      'User has been successfully removed!'
                                    }
                                  />
                                );
                              } else {
                                modalStack.push(
                                  <ErrorPopup
                                    onClose={() => {
                                      modalStack.pop();
                                      clearTimeout(timer);
                                    }}
                                    message={'Failed to remove user'}
                                  />
                                );
                              }
                            });
                          }}
                          onClose={() => modalStack.pop()}
                        />
                      );
                    }}
                  >
                    <FaTrash />
                  </IconWrapper>
                );
              },
            },
            {
              header: '',
              flexGrow: 0,
              minWidth: '230px',
              display: ({ row }) => {
                return <SendActivationWrapper />;
              },
            },
          ]}
          rows={users.filter(
            (f) => f.activated && (filter == '' || f.email.indexOf(filter) >= 0)
          )}
        />
      </UserListWrapper>
      <UserListWrapper>
        <SubHeader buttons={<></>}>{'Inactive users'}</SubHeader>
        <List<User>
          columnSettings={[
            {
              header: 'Email',
              display: ({ row }) => {
                return <div>{row.email}</div>;
              },
            },
            {
              header: 'Facilities',
              minWidth: '400px',
              display: ({ row }) => {
                return (
                  <div>{row.facilities.map((f) => f.name).join(', ')}</div>
                );
              },
            },
            {
              header: 'Role',
              display: ({ row }) => {
                return <div>{row.roles[0].name}</div>;
              },
            },
            {
              header: '',
              flexGrow: 0,
              minWidth: '30px',
              display: ({ row }) => {
                return (
                  <IconWrapper
                    onClick={() => {
                      editUser(row);
                    }}
                  >
                    <FaEdit />
                  </IconWrapper>
                );
              },
            },
            {
              header: '',
              flexGrow: 0,
              minWidth: '30px',
              display: ({ row }) => {
                return (
                  <IconWrapper
                    onClick={() => {
                      modalStack.push(
                        <ConfirmRemoveUser
                          email={row.email}
                          onSuccess={() => {
                            modalStack.pop();
                            removeUser!(row.id!, (success: boolean) => {
                              var timer = setTimeout(() => {
                                modalStack.pop();
                              }, 5000);
                              if (success) {
                                modalStack.push(
                                  <SuccessPopup
                                    onClose={() => {
                                      modalStack.pop();
                                      clearTimeout(timer);
                                    }}
                                    message={
                                      'User has been successfully removed!'
                                    }
                                  />
                                );
                              } else {
                                modalStack.push(
                                  <ErrorPopup
                                    onClose={() => {
                                      modalStack.pop();
                                      clearTimeout(timer);
                                    }}
                                    message={'Failed to remove user'}
                                  />
                                );
                              }
                            });
                          }}
                          onClose={() => modalStack.pop()}
                        />
                      );
                    }}
                  >
                    <FaTrash />
                  </IconWrapper>
                );
              },
            },
            {
              header: '',
              flexGrow: 0,
              minWidth: '230px',
              display: ({ row }) => {
                return (
                  <SendActivationWrapper>
                    <Button
                      onClick={() => {
                        sendActivationMail(row.id!, (success: boolean) => {
                          var timer = setTimeout(() => {
                            modalStack.pop();
                          }, 1500);
                          if (success) {
                            modalStack.push(
                              <SuccessPopup
                                onClose={() => {
                                  modalStack.pop();
                                  clearTimeout(timer);
                                }}
                                message={
                                  'Activation email has been sent to the user!'
                                }
                              />
                            );
                          } else {
                            modalStack.push(
                              <ErrorPopup
                                onClose={() => {
                                  modalStack.pop();
                                  clearTimeout(timer);
                                }}
                                message={'Failed to send activation email'}
                              />
                            );
                          }
                        });
                      }}
                    >
                      Send activation email
                    </Button>
                  </SendActivationWrapper>
                );
              },
            },
          ]}
          rows={users.filter(
            (f) =>
              !f.activated && (filter == '' || f.email.indexOf(filter) >= 0)
          )}
        />
      </UserListWrapper>
    </Content>
  );
};

export default UserListComponent;
