import { AIDefault } from 'constants/AIDefaults';
import { useReducer } from 'react';
import { Pricing, PricingGroup, PricingRange } from 'store/zone/reducer';
import { ActionReturns } from 'util/tshelpers';

export interface PricingEditorState extends Pricing {}

export const actions = {
  discount: (discountId: number, value: number) =>
    ({ type: 'discount', discountId, value } as const),

  setName: (name: string) => ({ type: 'setName', name } as const),

  setActive: (pricingId: number, active: boolean) =>
    ({ type: 'setActive', pricingId, active } as const),

  setMin: (pricingId: number, min: number) =>
    ({ type: 'setMin', pricingId, min } as const),

  setMax: (pricingId: number, max: number) =>
    ({ type: 'setMax', pricingId, max } as const),

  // Legacy application code.
  applyOldAIDefaults: (aiDefaults: AIDefault[]) =>
    ({ type: 'applyOldAiDefaults', aiDefaults } as const),

  // New functional application code.
  applyAIDefaultsFN: (
    fn: (pr: PricingRange, pgs: PricingGroup[]) => PricingRange
  ) => ({ type: 'applyAIDefaultsFN', fn } as const),

  addRange: (
    pricingId: number,
    duration: 'day' | 'hour' | 'week',
    segment: 'driveup' | 'booking',
    parentId: number | null | undefined
  ) => ({ type: 'addRange', pricingId, duration, segment, parentId } as const),
};

const modPricingRange = (
  pricingRanges: PricingRange[],
  pricingRangeId: number,
  modFunc: (pricing: PricingRange) => PricingRange
) =>
  pricingRanges.map((pricingRange) =>
    pricingRangeId === pricingRange.id ? modFunc(pricingRange) : pricingRange
  );

const PricingEditorReducer = (
  state: PricingEditorState,
  action: ActionReturns<typeof actions>
): PricingEditorState => {
  switch (action.type) {
    case 'setName':
      return { ...state, name: action.name };

    case 'setActive':
      return {
        ...state,
        prices: modPricingRange(state.prices, action.pricingId, (pricing) => ({
          ...pricing,
          active: action.active,
        })),
      };

    case 'setMin':
      return {
        ...state,
        prices: modPricingRange(state.prices, action.pricingId, (pricing) => ({
          ...pricing,
          min: action.min,
        })),
      };

    case 'setMax':
      return {
        ...state,
        prices: modPricingRange(state.prices, action.pricingId, (pricing) => ({
          ...pricing,
          max: action.max,
        })),
      };
    case 'applyAIDefaultsFN':
      return {
        ...state,
        prices: state.prices.map((pr) => action.fn(pr, state.priceGroups)),
      };

    case 'applyOldAiDefaults':
      return {
        ...state,
        prices: state.prices.map((price) => {
          function matches(
            Kind: AIDefault['Kind'],
            segment: PricingRange['segment']
          ) {
            return (
              (Kind === 'Driveup' && segment === 'driveup') ||
              (Kind === 'Booking' && segment === 'booking')
            );
          }

          const theDefault = action.aiDefaults.find((d) =>
            matches(d.Kind, price.segment)
          );
          if (!price.groupId) {
            function copy(min?: number, max?: number): PricingRange {
              if (min !== undefined && max !== undefined) {
                return { ...price, active: true, min, max };
              } else {
                return { ...price, active: false, min: 0, max: 0 };
              }
            }

            switch (price.duration) {
              case 'day':
                return copy(theDefault?.DayMin, theDefault?.DayMax);
              case 'hour':
                return copy(
                  theDefault?.MinuteMin === undefined
                    ? undefined
                    : Math.round(theDefault.MinuteMin * 60),
                  theDefault?.MinuteMax === undefined
                    ? undefined
                    : Math.round(theDefault.MinuteMax * 60)
                );
              case 'week':
                return copy(theDefault?.WeekMin, theDefault?.WeekMax);
            }
          } else {
            if (!!theDefault?.extraData) {
              var extra = theDefault?.extraData.find(
                (f) =>
                  f.duration === price.duration && f.groupId === price.groupId
              );
              if (!!extra) {
                price.min = extra.min;
                price.max = extra.max;
              }
            }
          }
          return price;
        }),
      };
    case 'addRange': {
      return {
        ...state,
        prices: [
          ...state.prices,
          {
            id: action.pricingId,
            active: true,
            toggleable: true,
            segment: action.segment,
            duration: action.duration,
            activationRangeId: action.parentId,
            min: 0,
            max: 0,
          },
        ],
      };
    }
    case 'discount': {
      return {
        ...state,
        priceDiscounts: state.priceDiscounts.map((priceDiscount) => {
          if (priceDiscount.id == action.discountId) {
            priceDiscount.discountPercentage = action.value;
          }
          return priceDiscount;
        }),
      };
    }
  }
};

const usePricingEditorReducer = (init: PricingEditorState) => {
  return useReducer(PricingEditorReducer, init);
};

export default usePricingEditorReducer;
