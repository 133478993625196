import Select from 'components/input/Select';
import { destinations, destinationsData } from 'containers/ZonePicker';
import { Facilities } from 'pages/admin/Users/userAdminContext';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { ApplicationState } from 'store/definitions';
import { selectHistoryFacilityMatch } from 'store/router/selector';
import {
  facilityChangeActive,
  facilityChangedComplete,
  zoneChangeActive,
} from 'store/ui/action';
import {
  getAdminStatus,
  getUser,
  selectUISettingSites,
  selectUISettingZones,
} from 'store/uiSettings/selectors';
import styled, { css } from 'styled-components';
import { FacilityPageProps } from 'topdefinitions';
import logoSrc from 'images/logo.png';
import { selectAvailableZones } from 'store/zone/selector';
import { ZoneFlag } from 'store/zone/reducer';
import { NavLink } from 'react-router-dom';
import Route from './Route';

const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;
const LeftContent = styled.div``;
const RightContent = styled.div`
  justify-content: flex-end;
  display: flex;
  align-items: center;

  div {
    margin-left: 20px;
  }
`;
const Logo = styled.img`
  width: 140px;
`;

const SelectWrapper = styled.div``;
const Link = styled.div`
  text-decoration: underline;
  color: ${(props) => props.theme.palette.primary.bg};
  cursor: pointer;
`;

const LinkButton = styled(NavLink)<{ active: boolean }>`
  &,
  &:active,
  &:hover,
  &:visited {
    padding: 10px 13px;
    margin: 0;

    color: inherit;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    border: 1px solid ${({ theme }) => theme.palette.common.lightBorder};
    border-radius: 5px;
    transition: background-color 0.2s;

    ${(props) =>
      props.active &&
      css`
        padding: 9px 12px;
        font-weight: bold;
        border: 2px solid ${({ theme }) => theme.palette.primary.bg};
      `}
  }
  &:hover {
    background: ${({ theme }) => theme.palette.grey.bg};
    outline: none;
  }

  &:focus {
    font-weight: 700;
  }
`;

interface Props {}
const NavBar: React.FC<Props> = () => {
  const isAdmin = useSelector(getAdminStatus);
  const history = useHistory();
  //const [onAdminSite, setOnAdminSite] = useState<boolean>();
  const onAdminSite = history.location.pathname.startsWith('/admin/');
  const routeInfo = useSelector(selectHistoryFacilityMatch);
  const match = useRouteMatch<FacilityPageProps['match']['params']>();
  const [currentFacility, setCurrentFacility] = useState<string>(
    routeInfo?.params.facilityId!
  );
  const dispatch = useDispatch();
  var zones = useSelector(selectAvailableZones);

  const [isElectricFacility, setElectricFacility] = useState<boolean>(
    zones
      .filter((f) => f.sitePath === routeInfo?.params.facilityId!)
      .filter((f) => f.zoneFlags?.indexOf(ZoneFlag.Electric) !== -1).length > 0
  );
  var user = useSelector(getUser);
  var facilities = user.facilities;
  const value = onAdminSite ? '' : routeInfo?.params.facilityId;
  const activeFacility = useSelector(
    (state: ApplicationState) => state.uiState.activeFacility
  );
  const globalFacilities = useSelector(selectUISettingSites);

  const zoneSites = useSelector(
    (state: ApplicationState) => state.uiSettings.siteZones
  );
  useEffect(() => {
    if (currentFacility !== activeFacility) {
      var facilityZones = zones.filter((f) => f.sitePath === activeFacility);

      setElectricFacility(
        facilityZones.filter(
          (f) => f.zoneFlags?.indexOf(ZoneFlag.Electric) !== -1
        ).length > 0
      );
      setCurrentFacility(activeFacility);
      dispatch(facilityChangedComplete());
    }
  }, [activeFacility]);
  var route = generatePath(
    routeInfo?.params?.zoneId ? Route.EVPriceZGraph : Route.EVPriceGraph,
    !routeInfo?.params ? { facilityId: facilities[0].id } : routeInfo!.params!
  );
  console.log(routeInfo);

  return (
    <Wrapper>
      <LeftContent>
        <Logo src={logoSrc} alt="logo" />
      </LeftContent>
      <RightContent>
        {!onAdminSite && isElectricFacility && (
          <LinkButton active={route === routeInfo!.url} to={route}>
            EV Prices
          </LinkButton>
        )}
        {isAdmin && (
          <SelectWrapper>
            <span>Facility: </span>
            <Select
              value={value}
              onChange={(e) => {
                if (e.target.value === '') return;
                const facility = facilities.find(
                  (f) => f.id === Number(e.target.value)
                );
                console.log(
                  e.target.value,
                  facilities.find((f) => f.id === Number(e.target.value))
                );
                const routeType = destinations.reduce(
                  (prev, cur) =>
                    match?.path.indexOf(cur.dest) === -1
                      ? prev
                      : prev.dest.length < cur.dest.length
                      ? cur
                      : prev,
                  { dest: '/', path: destinationsData['/'] }
                );
                var pathData = {
                  facilityId: e.target.value,
                } as { facilityId: string; zoneId?: number };
                var facilityZones = zoneSites.filter(
                  (f) => f.routePath === e.target.value
                );
                if (facilityZones.length === 1) {
                  pathData.zoneId = facilityZones[0].zoneId;
                } else if (facilityZones.length > 5) {
                  console.log(facilityZones.length);
                  pathData.zoneId = facilityZones[0].zoneId;
                }

                if (pathData.zoneId !== undefined) {
                  history.push(generatePath(routeType.path[1], pathData));
                } else {
                  history.push(generatePath(routeType.path[0], pathData));
                }

                //console.log(e.target.value, globalFacilities);
                const uiFac = globalFacilities.find(
                  (fi) => fi.path === e.target.value
                )!;

                dispatch(zoneChangeActive(pathData.zoneId ?? null));
                dispatch(
                  facilityChangeActive(
                    e.target.value,
                    uiFac.segments.length > 1 ? 'all' : uiFac.segments[0]
                  )
                );
              }}
            >
              {(onAdminSite
                ? [
                    { id: -1, name: '** Admin page **', route: '' },
                    ...facilities,
                  ]
                : facilities
              ).map((f) => (
                <option key={f.id} value={f.route}>
                  {f.name ?? f.route}
                </option>
              ))}
            </Select>
          </SelectWrapper>
        )}
        {!onAdminSite && isAdmin && (
          <Link
            onClick={() => {
              //setOnAdminSite(true);
              history.push('/admin/users');
            }}
          >
            Admin Page
          </Link>
        )}
        {/* {onAdminSite && (
          <Link
            onClick={() => {
              setOnAdminSite(false);
              history.goBack();
            }}
          >
            Back to homepage
          </Link>
        )} */}
        <Link
          onClick={() => {
            window.location.href = '/user/logout';
          }}
        >
          Logout
        </Link>
      </RightContent>
    </Wrapper>
  );
};

export default NavBar;
