import * as Actions from './action';
import { User } from 'pages/admin/Users/userAdminContext';
import { ActionReturns } from '../../util/tshelpers';
import { UISETTINGS } from './constants';
import { Pricing, ZoneConfig } from 'store/zone/reducer';
import { Events } from 'store/event/definitions';

export interface PriceProductExport {
  id: number;
  dateModified: Date;
  exporterType: string;
  exporterTargetID: string;
  sourceDZoneID: number;
  pricingSourceID: number | undefined;
  exporterTargetApiCredentialsID: number | undefined;
  zoneName: string | undefined;
  priceName: string | undefined;
}

export interface SiteZones {
  zoneId: number;
  routePath: string;
  zoneName: string;
  enabledPriceTypes: string[]; // can contain "hour" , "hour_min" , "hour_max", "day", "day_min" , "day_max", "week", "week_min" and/or "week_max"
  exports: PriceProductExport[];
  config?: ZoneConfig;
}

export enum FacilityFlag {
  NoFutureGraphDisplay = 1,
}
export interface Facility {
  segments: string[];
  path: string;
  name: string;
  id: number;
  facilityFlags?: FacilityFlag[];
  config?: {
    electricPricingLocation?: string;
    currencyDecimals?: number;
  };
}

export interface UISettings {
  user: User;
  hasUserAdmin: boolean;
  sites: Facility[];
  siteZones: SiteZones[];
  isAPDemo: boolean;
  events: Events;
  pricings: Pricing[];
}

const initialState: UISettings = {
  user: new User(),
  hasUserAdmin: false,
  sites: [],
  events: [],
  siteZones: [],
  isAPDemo: true,
  pricings: [],
};

type Actions = ActionReturns<typeof Actions>;

export function reducer(state = initialState, action: Actions): UISettings {
  switch (action.type) {
    case UISETTINGS.UPDATE_USER:
      return { ...state, user: action.user };
  }
  return state;
}
