import Button, { ButtonColors } from 'components/input/Button';
import Input from 'components/input/Input';
import Label from 'components/input/Label';
import Toggle from 'components/input/Toggle';
import useModalStack from 'components/Modal/useModalStack';
import PriceProductAIModal from 'pages/Pricing/PriceProductAIModal';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { PricingGroup, PricingRange, ZoneFlag } from 'store/zone/reducer';
import styled from 'styled-components';
import PricingRangeInput from './PricingRangeInput';
import usePricingEditorReducer, { actions } from './usePricingEditorState';
import disabledZonePricePairs from '../../constants/disabledZonePriceInfo';
import {
  NavTabWrapper,
  NavTab,
  navTabActiveClass,
} from '../../components/NavBar';
import NewRangeModal from './NewRangeModal';
import DeleteRangeConfirmModal from './DeleteRangeConfirmModal';
//import { groupBy } from 'lodash';
import groupBy from 'util/groupBy';
import { getDurationTitle } from 'util/pricing';
import { useSelector } from 'react-redux';
import { selectCurrentZone } from 'store/zone/selector';
import { hasFlag } from 'util/facility';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const nameClass = 'name';

const Inputs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 100px;
  grid-row-gap: 10px;
  padding-left: 110px;

  .${nameClass} {
    grid-area: 1/1/2/2;
  }
`;

const InTabInputs = styled.div`
  flex: 1;
  position: relative;
  top: -20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 100px;
  grid-row-gap: 10px;
  padding-left: 20px;
  padding-right: 40px;
  //padding-left: 110px;

  .${nameClass} {
    grid-area: 1/1/2/2;
  }
`;

const AskAiContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  span {
    margin-bottom: 5px;
  }
`;

const Segments = styled.div`
  grid-area: 2/1/3/3;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  margin: 0 auto;
  padding-top: 20px;
  //  margin-top: 20px;
  //  overflow: scroll;
`;

const Segment = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.bg};
    margin-bottom: 20px;
  }
`;

const SegmentLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100px;
`;

const PlusButton = styled.div`
  width: 20px;
  height: 20px;
  border-width: 1;
  border-color: black;
  border-style: solid;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 2.5em;
  font-weight: 300;
  padding: 0.5em;
  background-color: ${({ theme }) => theme.palette.primary.bg};
  // backgroundColor: palette.bg,

  // margin: 'auto',
  // position: 'relative',
`;

const getNumberPrefixFromString = (str: string) => {
  let regRes = /^\s*([0-9]+)\s*([a-zA-Z]+)/.exec(str);
  if (regRes) {
    return { amount: parseInt(regRes[1]), unit: regRes[2] };
  }
  return null;
};

const durationOrdering = (duration: PricingRange['duration']) => {
  const amountUnit = getNumberPrefixFromString(duration);
  if (amountUnit) {
    switch (amountUnit.unit.toLowerCase()) {
      case 'hour':
      case 'hours':
        return amountUnit.amount;
      case 'day':
      case 'days':
        return amountUnit.amount * 24;
      case 'week':
      case 'weeks':
        return amountUnit.amount * 24 * 7;
    }
  }
  switch (duration) {
    case 'hour':
      return 1;
    case 'day':
      return 24;
    case 'week':
      return 24 * 7;
  }
};

const DisabledOverLay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 16px;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.7);
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const GroupNavBar = styled.div`
  padding: 10px 0;
`;

const DISC_MARGIN = 10;
const AMOUNT_DISC_ON_ROW = 6;

const DiscountWrapper = styled.div<{ width: number; amount: number }>`
  display: flex;
  flex-wrap: wrap;
  width: ${(props) => props.width}px;
  > label {
    flex: 1;
    max-width: 50%;
    flex-grow: ${(props) => (props.amount >= AMOUNT_DISC_ON_ROW ? 0 : 1)};
    min-width: ${(props) =>
      (props.width - AMOUNT_DISC_ON_ROW * DISC_MARGIN * 2) /
      AMOUNT_DISC_ON_ROW}px;
    margin: 10px ${DISC_MARGIN}px 0;
  }
`;

const PriceProductNavTabWrapper = styled(NavTabWrapper)`
  flex-direction: column;
  justify-content: flex-end;
`;
const SegmentLabelWrapper = styled.div`
  > label {
    margin: 0 ${DISC_MARGIN}px;
  }
`;

const durationSort = (a: PricingRange, b: PricingRange) =>
  durationOrdering(a.duration) - durationOrdering(b.duration);

const hasActive = (prices: PricingRange[]) => !!prices.find((p) => p.active);
const hasInactive = (prices: PricingRange[]) => !!prices.find((p) => !p.active);

interface Props {
  priceProductEditorReducer: ReturnType<typeof usePricingEditorReducer>;
}

const isDisabledPrice = (zoneId: number | null, pricerange: PricingRange) => {
  return !!disabledZonePricePairs.find((dz) => {
    const res =
      (dz.zoneId === null || dz.zoneId === zoneId) &&
      (dz.duration === 'all' || dz.duration === pricerange.duration) &&
      dz.kind === pricerange.segment;
    // console.log(
    //   pricerange.segment,
    //   pricerange.duration,
    //   res,
    //   dz,
    //   pricerange,
    //   zoneId
    // );
    return res;
  });
};

const segments = ['Driveup', 'Booking'];

const groupKeyToSortValue = (n: number | null) =>
  n === null || n === undefined
    ? 0
    : n == 0
    ? 1
    : Math.abs(n) * 2 + (n < 0 ? 1 : 0);

const PriceProductEditor: FC<Props> = ({ priceProductEditorReducer }) => {
  const modalStack = useModalStack();

  const [editState, d] = priceProductEditorReducer;
  const [activeSegment, setActiveSegment] = useState(
    !!editState.prices.find(
      (pr) => pr.segment.toLowerCase() === segments[0].toLowerCase()
    )
      ? segments[0]
      : segments[1]
  );
  const [adjustedWidth, setAdjustedWidth] = useState(0);

  // Todo - When ready for EV in frontend
  const zone = useSelector(selectCurrentZone);
  //const [isEvCharge, setIsEvCharge] = useState(
  //hasFlag(zone, ZoneFlag.Electric)
  // const isEvCharge = useMemo(
  //   () =>
  //     !!editState.prices.find(
  //       (pr) =>
  //         'number' === typeof pr.groupId &&
  //         editState.priceGroups.find((prg) => prg.id === pr.groupId)?.condition
  //           ?.surchargeGroup === 'electric'
  //     ),
  //   [editState]
  // );

  // const [isEvCharge, setIsEvCharge] = useState<boolean>(false);

  // console.log('Is ev charge?', isEvCharge, editState);

  useEffect(() => {
    setAdjustedWidth(document.getElementById('main-segment')?.offsetWidth!);
  }, []);

  //console.log('DEBUG HERE');

  // build a "sorted" list of groups: first put everything into a "ID" set, secondly map it to the ID and a sort-key, finally sort the set.
  const groups = [
    ...editState.prices
      .map((p) => (p.groupId === undefined ? null : p.groupId))
      .reduce((acc, cur) => (acc.add(cur), acc), new Set<number | null>()),
  ]
    .map((gid) => ({ gid, sk: groupKeyToSortValue(gid) }))
    .sort((a, b) => a.sk - b.sk);

  const segmentRanges = useMemo(
    () =>
      editState.prices
        .filter((p) => p.segment === activeSegment.toLowerCase())
        .sort(durationSort),
    [editState.prices, activeSegment]
  );

  // Create groups of surcharge ranges
  const surchargeRanges = useMemo(() => {
    return (
      editState.prices
        // First locate prices and their associated pricegroup
        .map((p) =>
          'number' !== typeof p.groupId
            ? null
            : {
                p,
                prg: editState.priceGroups.find(
                  (prg) => prg.id === p.groupId && prg.condition?.surchargeGroup
                ),
              }
        )
        // for those with groups that are surcharge groups, output the groupname, pricerange and group
        .map((pi) =>
          !pi ||
          !pi.prg ||
          !pi.prg.condition ||
          !pi.prg.condition.surchargeGroup
            ? null
            : { sn: pi.prg.condition.surchargeGroup, p: pi.p, gr: pi.prg }
        )
        // finally combined into buckets ( bliss when groupBy becomes standard )
        .reduce(
          (acc, pi) =>
            !pi
              ? acc
              : !acc[pi.sn]
              ? { ...acc, [pi.sn]: [pi.p] }
              : { ...acc, [pi.sn]: [...acc[pi.sn], pi.p] },
          {} as any
        )
    );
  }, [editState, activeSegment]) as { [n: string]: PricingRange[] };

  const isEvCharge =
    surchargeRanges &&
    surchargeRanges['electric'] &&
    surchargeRanges['electric'].length;

  console.log(surchargeRanges);

  const discountRange = editState.priceDiscounts.filter(
    (f) => f.segment === null || f.segment === activeSegment.toLowerCase()
  );
  console.log(
    activeSegment,
    editState.prices,
    segmentRanges,
    editState.priceGroups
  );

  // const bookingSegment = useMemo(
  //   () =>
  //     editState.prices
  //       .filter((p) => p.segment === 'booking')
  //       .sort(durationSort),
  //   [editState.prices]
  // );
  // const driveupSegment = useMemo(
  //   () =>
  //     editState.prices
  //       .filter((p) => p.segment === 'driveup')
  //       .sort(durationSort),
  //   [editState.prices]
  // );

  function iterateSegments(
    groupId: null | number,
    parentId: null | undefined | number
  ): PricingRange[] {
    var _segments = segmentRanges.reduce((acc, curr) => {
      if (!(curr.activationRangeId !== parentId || curr.groupId != groupId)) {
        acc.push(curr);
      }
      return acc;
    }, [] as PricingRange[]);
    return _segments.reduce(
      (acc, curr) => [...acc, curr, ...iterateSegments(groupId, curr.id)],
      [] as PricingRange[]
    );
  }

  function renderRanges(
    groupId: null | number,
    parentId: null | undefined | number
  ): JSX.Element[] {
    var group = editState.priceGroups.find((f) => f.id === groupId);
    var _segments = iterateSegments(groupId, parentId);

    return _segments.map((price) => {
      // Todo - Fix visuals with electical
      // var charge = isEvCharge
      //   ? surchargeRanges['electric'].find((f) => {
      //       return f.duration == price.duration;
      //     })
      //   : undefined;
      var charge = undefined;
      return (
        <PricingRangeInput
          key={price.id}
          title={
            (_segments.length === 1
              ? group?.name
              : price.duration +
                (!price.activationRangeId
                  ? ''
                  : `(Activated by ${
                      segmentRanges.find(
                        (sr) => sr.id === price.activationRangeId
                      )!.duration
                    })`)) ?? ''
          }
          charge={charge}
          active={price.active}
          toggleable={price.toggleable} // && price.duration !== 'week'
          overlay={
            undefined
            // price.duration === 'week' && (
            //   <DisabledOverLay>Cooming soon</DisabledOverLay>
            // )
          }
          min={price.min}
          max={price.max}
          onActiveChanged={(v) => d(actions.setActive(price.id, v))}
          onMaxChanged={(v) => d(actions.setMax(price.id, v))}
          onMinChanged={(v) => d(actions.setMin(price.id, v))}
        />
      );
    });
  }
  console.log(isEvCharge, activeSegment.toLowerCase() === 'driveup');

  //console.log('DISCOUNT', discountRange, editState.priceDiscounts);
  return (
    <Wrapper>
      <Inputs></Inputs>

      <PriceProductNavTabWrapper>
        <InTabInputs>
          <Label label="Price Product Name" className={nameClass}>
            <Input
              type="text"
              value={editState.name}
              onChange={(eve) => d(actions.setName(eve.target.value))}
            />
          </Label>

          <AskAiContainer>
            {hasFlag(zone, ZoneFlag.NoAIPrediction) && (
              <span>No AI prediction available</span>
            )}
            <Button
              disabled={hasFlag(zone, ZoneFlag.NoAIPrediction)}
              onClick={() => {
                modalStack.push(
                  <PriceProductAIModal
                    priceProductEditorReducer={priceProductEditorReducer}
                    zoneId={editState.parentZoneId}
                  />
                );
              }}
            >
              Ask the AI
            </Button>
          </AskAiContainer>
        </InTabInputs>
        <GroupNavBar>
          {segments
            .filter((seg) =>
              editState.prices.find(
                (pr) => pr.segment.toLowerCase() === seg.toLowerCase()
              )
            )
            .map((seg) => (
              <NavTab
                key={seg}
                // style={{ flex: 1 }}
                onClick={(e) => {
                  console.log('Test');
                  setActiveSegment(seg);
                  e.preventDefault();
                }}
                to=""
                isActive={() => true}
                activeClassName={
                  seg === activeSegment ? navTabActiveClass : 'otherdummy'
                }
                //className={seg === activeSegment ? navTabActiveClass : 'otherdummy'}
              >
                {seg}
              </NavTab>
            ))}
        </GroupNavBar>
      </PriceProductNavTabWrapper>

      <Segments id="main-segment">
        <Segment>
          {groups.map((r) => renderRanges(r.gid, null))}
          {/* <div style={{ margin: 'auto' }}> Add button for groups
            <Button
              color={ButtonColors.add}
              onClick={() => {
                modalStack.push(
                  <NewRangeModal
                    priceProductEditorReducer={priceProductEditorReducer}
                    segment={
                      activeSegment.toLowerCase() as 'driveup' | 'booking'
                    }
                  />
                  // <PriceProductAIModal
                  //   priceProductEditorReducer={priceProductEditorReducer}
                  //   zoneId={editState.parentZoneId}
                  // />
                );
              }}
            />
          </div> */}
        </Segment>
        {surchargeRanges['electric'] &&
          activeSegment.toLowerCase() === 'driveup' && (
            <SegmentLabelWrapper>
              <Label label="EV Charge" className={nameClass} />
              <Segment>
                {surchargeRanges['electric'].map((price) => {
                  var group = editState.priceGroups.find(
                    (f) => f.id === price.groupId
                  );
                  console.log(price);
                  return (
                    <PricingRangeInput
                      key={price.id}
                      title={getDurationTitle(
                        price.duration,
                        price.segment,
                        group
                      )}
                      active={price.active}
                      toggleable={price.toggleable} // && price.duration !== 'week'
                      overlay={
                        undefined
                        // price.duration === 'week' && (
                        //   <DisabledOverLay>Cooming soon</DisabledOverLay>
                        // )
                      }
                      min={price.min}
                      max={price.max}
                      onActiveChanged={(v) => d(actions.setActive(price.id, v))}
                      onMaxChanged={(v) => d(actions.setMax(price.id, v))}
                      onMinChanged={(v) => d(actions.setMin(price.id, v))}
                    />
                  );
                })}
              </Segment>
            </SegmentLabelWrapper>
          )}
        {adjustedWidth != 0 && discountRange.length > 0 && (
          <Segment style={{ flexDirection: 'column' }}>
            <SegmentLabelWrapper>
              <Label label="Discount (%)" className={nameClass} />
              <DiscountWrapper
                width={adjustedWidth}
                amount={discountRange.length}
              >
                {discountRange
                  .sort((a, b) => {
                    if (a.name > b.name) {
                      return 1;
                    } else if (b.name > a.name) {
                      return -1;
                    }
                    return 0;
                  })
                  .map((f) => (
                    <Label label={f.name}>
                      <Input
                        type="number"
                        value={f.discountPercentage}
                        onChange={(e) => {
                          console.log(e.target.value);
                          var value = Number(e.target.value);
                          d(actions.discount(f.id, value));
                        }}
                      />
                    </Label>
                  ))}
              </DiscountWrapper>
            </SegmentLabelWrapper>
          </Segment>
        )}
        {/* <Segment>
          <SegmentLabel>
            Driveup
            <Toggle
              checked={hasActive(driveupSegment)}
              halfwayOffStyling={
                hasActive(driveupSegment) && hasInactive(driveupSegment)
              }
              onChange={(eve) => {
                driveupSegment.forEach((price) =>
                  d(actions.setActive(price.id, eve.target.checked))
                );
              }}
            />
          </SegmentLabel>

          {driveupSegment.map((price) => (
            <PricingRangeInput
              key={price.id}
              title={price.duration}
              active={price.active}
              toggleable={price.toggleable && price.duration !== 'week'}
              overlay={
                price.duration === 'week' && (
                  <DisabledOverLay>Cooming soon</DisabledOverLay>
                )
              }
              min={price.min}
              max={price.max}
              onActiveChanged={(v) => d(actions.setActive(price.id, v))}
              onMaxChanged={(v) => d(actions.setMax(price.id, v))}
              onMinChanged={(v) => d(actions.setMin(price.id, v))}
            />
          ))}
        </Segment>
        <Segment>
          <SegmentLabel>
            Booking
            <Toggle
              checked={hasActive(bookingSegment)}
              halfwayOffStyling={
                hasActive(bookingSegment) && hasInactive(bookingSegment)
              }
              disabled={true}
              onChange={(eve) => {
                bookingSegment.forEach(
                  (price) =>
                    !isDisabledPrice(
                      priceProductEditorReducer[0].parentZoneId,
                      price
                    ) && d(actions.setActive(price.id, eve.target.checked))
                );
              }}
            />
          </SegmentLabel>

          {bookingSegment.map((price) => (
            <PricingRangeInput
              key={price.id}
              title={price.duration}
              active={price.active}
              toggleable={false}
              min={price.min}
              max={price.max}
              onActiveChanged={(v) => d(actions.setActive(price.id, v))}
              onMaxChanged={(v) => d(actions.setMax(price.id, v))}
              onMinChanged={(v) => d(actions.setMin(price.id, v))}
            />
          ))}
          <DisabledOverLay>Coming soon</DisabledOverLay>
        </Segment> */}
      </Segments>
    </Wrapper>
  );
};

export default PriceProductEditor;
