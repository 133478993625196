import Input from 'components/input/Input';
import Label from 'components/input/Label';
import Range from 'components/input/Range';
import Select from 'components/input/Select';
import Toggle from 'components/input/Toggle';
import moment from 'moment';
import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectAvailableZones, selectCurrentZone } from 'store/zone/selector';
import styled from 'styled-components';
import { EventEditorState } from './useEventEditorState';
import { selectHistoryFacilityMatch } from '../../store/router/selector';
import { ZoneEventData } from 'store/event/definitions';

const nameClass = 'name';
const startDateClass = 'startDate';
const endDateClass = 'endDate';
const zoneClass = 'zoneClassId';
const kindClass = 'kind';
const demandClass = 'demand';
const timeOfDayClass = 'timeOfDay';
export const activeClass = 'active';
export const fullDayClass = 'fullDay';

export const TimeOfDayText = {
  fullDay: 'Full day',
  morning: 'Morning',
  noon: 'Noon',
  evening: 'Evening',
  night: 'Night',
};

const Wrapper = styled.div`
  padding-left: 2.5rem;
  padding-right: 2.5rem;
`;

const GeneralGrid = styled.div`
  padding-top: 2.5rem;
  position: sticky;
  top: 0;
  z-index: 10;
  display: grid;
  background-color: white;
  grid-template-columns: 150px 150px 150px 220px 220px;
  grid-template-rows: repeat(5, 0fr);
  grid-column-gap: 3vw;
  grid-row-gap: 10px;
  align-items: flex-end;

  .${nameClass} {
    grid-area: 1/1/2/3;
  }
  .${kindClass} {
    grid-area: 1/3;
  }
  .${startDateClass} {
    grid-area: 1/4;
  }
  .${endDateClass} {
    grid-area: 1/5;
  }
  /* .${zoneClass} {
    grid-area: 2/2/3/2;
  }
  .${timeOfDayClass} {
    grid-area: 3/2/4/3;
  } */
  .${demandClass} {
    grid-area: 4/1/5/6;
  }
  .${activeClass} {
  }
`;

const DemandGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 180px);
  grid-column-gap: 3vw;
  grid-row-gap: 10px;
  grid-auto-flow: row;
  grid-auto-rows: 0fr; //minmax(120px, 1fr);
  //grid-template-rows: repeat(5, 0fr);
  .${zoneClass} {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  .${demandClass} {
    grid-column-start: 2;
    grid-column-end: 6;
  }
`;

const TogglesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 3vw;
`;

const useStringSetter = (setFn: (x: string) => void) =>
  useCallback(
    (eve: React.ChangeEvent<HTMLInputElement>) => {
      setFn(eve.target.value);
    },
    [setFn]
  );

const useBoolSetter = (setFn: (x: boolean) => void) =>
  useCallback(
    (eve: React.ChangeEvent<HTMLInputElement>) => {
      setFn(eve.target.checked);
    },
    [setFn]
  );

interface Props {
  eventEditorState: EventEditorState;
  fixedZones: boolean;
  fixedEvent: boolean;
}

const updateZoneData = (
  eds: EventEditorState,
  idx: number,
  zd: ZoneEventData
) => {
  if (!eds.rzData.zoneData) {
    eds.setRZData({ routeData: zd });
  } else {
    eds.setRZData({
      zoneData: eds.rzData.zoneData.map((ozd, iidx) =>
        iidx === idx ? zd : ozd
      ),
    });
  }
};

const EventEditor: FC<Props> = ({
  eventEditorState,
  fixedZones,
  fixedEvent,
}) => {
  const path = useSelector(selectHistoryFacilityMatch) as any;
  const fullDay = eventEditorState.fullDay;

  const activeZone = useSelector(selectCurrentZone);

  let zones = useSelector(selectAvailableZones).filter(
    (f) => path !== null && f.sitePath == path?.params.facilityId
  );

  let zoneDataList = eventEditorState.rzData.zoneData ?? [
    eventEditorState.rzData.routeData!,
  ];

  console.log('Zones', activeZone, zoneDataList);
  if (!!activeZone && fixedEvent) {
    zoneDataList = zoneDataList.filter((f) => f.zoneID === activeZone.id);
  }

  console.log('Event:', eventEditorState);
  console.log(zoneDataList);

  const inputDurationType = fullDay ? 'date' : 'datetime-local';
  const inputDurationFormatStr = fullDay ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm';

  return (
    <Wrapper>
      <GeneralGrid>
        <Label label="Event Name" className={nameClass}>
          <Input
            disabled={fixedEvent}
            onChange={useStringSetter(eventEditorState.setName)}
            value={eventEditorState.name}
          />
        </Label>
        <Label
          label={fullDay ? 'Start Date' : 'Start Time'}
          className={startDateClass}
        >
          <Input
            disabled={fixedEvent}
            type={inputDurationType}
            value={moment
              .utc(eventEditorState.startDate)
              .format(inputDurationFormatStr)}
            onChange={(evt) => {
              // console.log(
              //   'Setting start date:',
              //   evt.target.value,
              //   moment(evt.target.value).toDate(),
              //   moment.utc(evt.target.value).toDate(),
              //   'Was previously',
              //   eventEditorState.startDate
              // );

              return eventEditorState.setStartDate(
                moment
                  .utc(evt.target.value)
                  .startOf(fullDay ? 'day' : 'hour')
                  .toDate()
                //moment.utc(evt.target.value).minute(0).second(0).toDate()
              );
            }}
          />
        </Label>
        <Label
          label={fullDay ? 'End Date' : 'End Time'}
          className={endDateClass}
        >
          <Input
            disabled={fixedEvent}
            type={inputDurationType}
            value={moment
              .utc(eventEditorState.endDate)
              .format(inputDurationFormatStr)}
            onChange={(evt) => {
              // const date1 = moment
              //   .utc(evt.target.value)
              //   .minute(0)
              //   .second(0)
              //   .toDate();
              // const date2 = moment
              //   .utc(date1)
              //   .clone()
              //   .add(1, fullDay ? 'days' : 'hour')
              //   .toDate();
              // const date3 = moment
              //   .utc(date2)
              //   .clone()
              //   .subtract(1, 'minutes')
              //   .toDate();
              //const date4 = moment.utc(date3).toDate();
              //eventEditorState.setEndDate(date4);

              if (fullDay) {
                // reload 3
                eventEditorState.setEndDate(
                  moment
                    .utc(evt.target.value)
                    .endOf('day')
                    .startOf('second')
                    .toDate()
                );
              } else {
                eventEditorState.setEndDate(
                  moment.utc(evt.target.value).startOf('hour').toDate()
                );
              }
            }}
          />
        </Label>
        <Label label="Event Type" className={kindClass}>
          <Select
            disabled={fixedEvent}
            value={eventEditorState.kind}
            onChange={(eve) => {
              var kind = eve.target.value;
              eventEditorState.setKind(kind);
              if (kind === 'Closed') {
                zoneDataList.forEach((zd, idx) => {
                  updateZoneData(eventEditorState, idx, {
                    ...zd,
                    customDemand: -100,
                  });
                });
              } else {
                zoneDataList.forEach((zd, idx) => {
                  updateZoneData(eventEditorState, idx, {
                    ...zd,
                    customDemand: zd.systemDemand ?? 0,
                  });
                });
              }
            }}
          >
            <option disabled value=""></option>
            {/*<option value="Public Holiday">Public Holiday</option>*/}
            <option value="Sport">Sport</option>
            <option value="Concert">Concert</option>
            <option value="Conference">Conference</option>
            <option value="Strike">Strike</option>
            <option value="Closed">Closed</option>
            <option value="Other">Other</option>
          </Select>
        </Label>
      </GeneralGrid>
      <DemandGrid>
        {zoneDataList.length > 1 ? (
          <>
            <Label label="Zone" className={zoneClass} />
            <Label label="Demand Effect" className={demandClass} />
          </>
        ) : (
          <></>
        )}

        {zoneDataList.map((zd, idx) => {
          console.log(zd);
          return (
            <React.Fragment key={zd.zoneID}>
              <Label
                label={zoneDataList.length <= 1 ? 'Zone' : ''}
                className={zoneClass}
              >
                <Select
                  className={zoneClass}
                  disabled={fixedZones}
                  value={
                    eventEditorState.rzData.zoneData ? zd.zoneID : undefined
                  } //eventEditorState.zoneId ?? undefined} //? eventEditorState.zoneId : ''}
                  onChange={(eve) => {
                    let zoneValue = eve.target.value;
                    console.log(eventEditorState);
                    if (
                      eventEditorState.rzData.zoneData &&
                      eventEditorState.rzData.zoneData.length === 1 &&
                      zoneValue === ''
                    ) {
                      // Zone ID was previously valid but we've selected "all zones", move zonedata to single zone
                      eventEditorState.setRZData({ routeData: zd });
                    } else if (
                      !eventEditorState.rzData.zoneData &&
                      zoneValue !== ''
                    ) {
                      // move zone from routeData (as meaning all-zones) to a zonedata list to indicate explicit zones.
                      eventEditorState.setRZData({
                        zoneData: [
                          {
                            ...zd,
                            zoneID: parseInt(zoneValue),
                          },
                        ],
                      });
                    } else if (zoneValue !== '') {
                      updateZoneData(eventEditorState, idx, {
                        ...zd,
                        zoneID: parseInt(zoneValue),
                      });
                    }
                    // eventEditorState.setZoneId(
                    //   eve.target.value ? Number(eve.target.value) : null
                    //   // (eve.target.value === '' ? null : eve.target.value)!
                    // )
                  }}
                >
                  <option value={''}>All zones</option>
                  {zones.map((zone) => {
                    return (
                      <option
                        selected={zd.zoneID === zone.id}
                        key={zone.id}
                        value={zone.id}
                      >
                        {zone.name}
                      </option>
                    );
                  })}
                </Select>
              </Label>
              <Label
                label={zoneDataList.length <= 1 ? 'Demand Effect' : ''}
                className={demandClass}
              >
                <Range
                  value={(zd.customDemand ?? zd.systemDemand)!}
                  suggestedValue={zd.systemDemand ?? undefined} // convert null to undefined
                  onChange={(v) => {
                    updateZoneData(eventEditorState, idx, {
                      ...zd,
                      customDemand: v,
                    });
                  }}
                  //value={0}
                  //onChange={(v) => {}}
                  min={-100}
                  max={100}
                  stepSize={10}
                  valueDisplayFormatter={(v, sv) =>
                    sv !== undefined
                      ? sv === v
                        ? `${Math.round(v)}% Suggested Demand effect`
                        : `${Math.round(v)}% Demand effect  (${Math.round(
                            sv
                          )} suggested)`
                      : `${Math.round(v)}% Demand effect`
                  }
                  minLabel="Decrease"
                  maxLabel="Increase"
                />
              </Label>
            </React.Fragment>
          );
        })}
      </DemandGrid>

      <TogglesWrapper className={activeClass}>
        {!fixedEvent && (
          <Label row label="Active">
            <Toggle
              checked={
                zoneDataList.length > 1
                  ? true
                  : zoneDataList.length < 1
                  ? false
                  : zoneDataList[0].active
              }
              onChange={(evt) => {
                if (zoneDataList.length === 1) {
                  updateZoneData(eventEditorState, 0, {
                    ...zoneDataList[0],
                    active: evt.target.checked,
                  });
                }
              }}
            />
          </Label>
        )}
        {!fixedEvent && (
          <Label row label="Full day">
            <Toggle
              checked={eventEditorState.fullDay}
              onChange={(evt) => {
                const fullDayNext = evt.target.checked;
                if (fullDayNext) {
                  eventEditorState.setStartDate(
                    moment
                      .utc(eventEditorState.startDate)
                      .startOf('day')
                      .toDate()
                  );
                  eventEditorState.setEndDate(
                    moment
                      .utc(eventEditorState.endDate)
                      .endOf('day')
                      .startOf('second')
                      .toDate()
                  );
                }
                eventEditorState.setFullDay(fullDayNext);
              }}
            />
          </Label>
        )}
      </TogglesWrapper>
    </Wrapper>
  );
  // useBoolSetter(eventEditorState.setActive)
};

export default EventEditor;
