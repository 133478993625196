import Button, { ButtonColors } from 'components/input/Button';
import List from 'components/List';
import Main from 'components/Main';
import NavBar from 'components/NavBar';
import useModalStack from 'components/Modal/useModalStack';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentZone } from 'store/zone/selector';
import AddEventModal from './AddEventModal';
import ModEventModal from './ModEventModal';
import Graph from '../../components/Graph';
import GraphParams from './GraphParams';
import SubHeader from 'components/SubHeader';
import DateNavigation from './DateNavigation';
import { uiDateMove } from 'store/ui/action';
import { ApplicationState } from 'store/definitions';
import moment from 'moment';
import DeleteButton from 'components/input/DeleteButton';
import { Event, ZoneEventData } from '../../store/event/definitions';
import RemEventModal from './RemEventModal';
import { durationToMomentDuration } from 'store/ui/uiutil';
import styled from 'styled-components';
import AccDemandSticker from 'containers/AccDemandSticker';
import { TimeOfDayText } from 'containers/EventEditor';
import { DemandContextProvider } from 'components/Graph/DemandContext';
import { selectHistoryFacilityMatch } from 'store/router/selector';
import { getUser } from 'store/uiSettings/selectors';
import { selectAllEventsFromCurrentFacility } from 'store/event/selector';

const GraphTop = styled.div`
  display: flex;
  flex-direction: row;
`;
const EventLink = styled.div`
  color: ${({ theme }) => theme.palette.primary.bg};
  font-weight: bold;
  :hover {
    color: rgb(137, 87, 160);
    -webkit-text-decoration: underline;
    text-decoration: underline;
    -webkit-text-decoration-color: rgb(117, 210, 207);
    text-decoration-color: rgb(117, 210, 207);
    -webkit-text-decoration-thickness: 2px;
    text-decoration-thickness: 2px;
    text-underline-position: under;
    cursor: pointer;
  }
`;

const OtherDateGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-flow: row;
  grid-auto-rows: 0fr; //minmax(120px, 1fr);
  grid-row-gap: 1vw;
  grid-column-gap: 1vw;
`;

type Holiday = { next: Event; other: Event[] };

const dateCompare = (a: string, b: string) =>
  new Date(a).valueOf() - new Date(b).valueOf();

const findMinMaxOfEvent = (
  ev: Event,
  valuePick: (zd: ZoneEventData) => number | undefined
) => {
  let min = Number.POSITIVE_INFINITY;
  let max = Number.NEGATIVE_INFINITY;
  let found = false;
  for (const zd of ev.zoneData ?? [ev.routeData!]) {
    const value = valuePick(zd);
    if (value !== null && value !== undefined) {
      found = true;
      min = Math.min(min, value);
      max = Math.max(max, value);
    }
  }
  if (!found) return undefined;
  else return { singleValue: min === max, min, max };
};

const DemandPage: FC = () => {
  const now = new Date().valueOf();
  const modalStack = useModalStack();

  //const zone = useSelector(selectCurrentZone);
  const dispatch = useDispatch();
  const duration = useSelector(
    (root: ApplicationState) => root.uiState.duration
  );
  const mdur = durationToMomentDuration(duration);
  const startDate = useSelector(
    (root: ApplicationState) => root.uiState.startDate
  );
  const path = useSelector(selectHistoryFacilityMatch) as any;
  const endDate = useSelector((root: ApplicationState) => root.uiState.endDate);

  const events = useSelector(selectAllEventsFromCurrentFacility) || [];
  //const events = useSelector((root: ApplicationState) => root.eventData) || [];

  const root = useSelector((root: ApplicationState) => root);
  var user = useSelector(getUser);
  var facility = user.facilities.find(
    (f) => f.route === path.params.facilityId
  );
  const customRows = events
    .filter(
      (f) =>
        //f.routeId == facility?.id &&
        (f.holidayID === null || f.holidayID === undefined) &&
        (!path.params.zoneId ||
          !!f.routeData ||
          f.zoneData!.some((zd) => zd.zoneID == path.params.zoneId))
    )
    .map((e: Event) => ({
      ...e,
      timeOfDay: TimeOfDayText[e.timeOfDay as keyof typeof TimeOfDayText],
    }));
  const holidays = [
    ...new Set(
      events
        .filter((ev) => ev.holidayID !== null && ev.holidayID !== undefined)
        .map((ev) => ev.holidayID!)
    ),
  ];
  const holidayRows = (
    holidays
      .map((hid) => {
        let holidayRow: Event | null = null;
        let other = [] as Event[];
        for (let i = 0; i < events.length; i++) {
          const f = events[i];
          if (
            f.holidayID === hid &&
            (!path.params.zoneId ||
              !!f.routeData ||
              f.zoneData!.some((zd) => zd.zoneID == path.params.zoneId))
          ) {
            if (moment.utc(f.startDate).valueOf() > now) {
              if (!holidayRow || f.startDate < holidayRow!.startDate) {
                holidayRow = f;
                continue;
              }
            }
            other.push(f);
          }
        }
        if (!holidayRow) return null;
        return {
          next: holidayRow,
          other: other.sort((a, b) => dateCompare(a.startDate, b.startDate)),
        };
      })
      .filter((hr) => hr) as Holiday[]
  ).sort((a, b) => dateCompare(a.next.startDate, b.next.startDate));
  // zone == null
  //   ? []
  //   : zone.events.map((e) => ({
  //       ...e,
  //       timeOfDay: e.fullDays ? 'Full day' : 'Part of day',
  //     }));

  return (
    <Main>
      <NavBar />

      <DemandContextProvider>
        <GraphTop>
          <GraphParams />
          <AccDemandSticker />
        </GraphTop>
        <Graph kind="demand" />
      </DemandContextProvider>
      <DateNavigation
        // prevLabel={moment.utc(startDate).add(-1, mdur).format('YY-M-D')}
        // nextLabel={moment.utc(startDate).add(1, mdur).format('YY-M-D')}
        prevLabel={moment.utc(startDate).add(-1, mdur).format('yyyy-MM-DD')}
        nextLabel={moment.utc(startDate).add(1, mdur).format('yyyy-MM-DD')}
        onNextClick={() => dispatch(uiDateMove(1))}
        onPreviousClick={() => dispatch(uiDateMove(-1))}
      />

      <SubHeader
        buttons={
          <Button
            color={ButtonColors.add}
            onClick={() => modalStack.push(<AddEventModal />)}
          >
            Add
          </Button>
        }
      >
        Special Event overrides
      </SubHeader>

      <List<Event & { timeOfDay: string }>
        columnSettings={[
          {
            header: 'Event Name',
            display: ({ row }) => {
              return (
                <EventLink
                  onClick={(evt) => {
                    modalStack.push(
                      <ModEventModal holiday={false} id={row.dateID!} />
                    );
                    evt.preventDefault();
                  }}
                >
                  {row.name}
                </EventLink>
              );
            },
          },
          {
            header: 'Event Type',
            display: 'kind',
          },
          {
            header: 'Demand Effect',
            display: ({ row }) => {
              const minMax = findMinMaxOfEvent(row, (zd) => zd.customDemand);
              if (!minMax) return <>--</>;
              else if (minMax.singleValue) return <>{minMax.min}</>;
              else return <>{`${minMax.min} to ${minMax.max}`}</>;
            },
          },
          {
            header: 'Start',
            display: ({ row }) => (
              <>
                {row.fullDay
                  ? moment.utc(row.startDate).format('yyyy-MM-DD')
                  : moment.utc(row.startDate).format('yyyy-MM-DD HH:mm')}
              </>
            ),
          },
          {
            header: 'End',
            display: ({ row }) => (
              <>
                {row.fullDay
                  ? moment.utc(row.endDate).format('yyyy-MM-DD')
                  : moment.utc(row.endDate).format('yyyy-MM-DD HH:mm')}
              </>
            ),
          },
          {
            header: 'Active',
            display: ({ row }) => (
              <>
                {(
                  row.routeData
                    ? row.routeData.active
                    : row.zoneData
                    ? row.zoneData.some((zd) => zd.active)
                    : true
                )
                  ? 'Yes'
                  : 'No'}
              </>
            ),
          },
          {
            header: '',
            flexGrow: 0,
            minWidth: '50px',
            display: ({ row }) => (
              <DeleteButton
                onClick={(evt) => {
                  modalStack.push(<RemEventModal id={row.dateID!} />);
                  evt.preventDefault();
                }}
              />
            ),
          },
        ]}
        rows={customRows}
      />
      {/*<SubHeader buttons={<></>}>Public Holidays</SubHeader>

      <List<Holiday>
        columnSettings={[
          {
            header: 'Holiday Name',
            display: ({ row }) => {
              return (
                <EventLink
                  onClick={(evt) => {
                    modalStack.push(
                      <ModEventModal holiday={true} id={row.next.dateID!} />
                    );
                    evt.preventDefault();
                  }}
                >
                  {row.next.name}
                </EventLink>
              );
            },
          },
          {
            header: 'Auto Demand',
            display: ({ row }) => {
              const minMax = findMinMaxOfEvent(
                row.next,
                (zd) => zd.systemDemand
              );
              if (!minMax) return <>--</>;
              else if (minMax.singleValue) return <>{minMax.min.toFixed(2)}</>;
              else
                return (
                  <>{`${minMax.min.toFixed(2)} to ${minMax.max.toFixed(2)}`}</>
                );
            },
          },
          {
            header: 'Demand Setting',
            display: ({ row }) => {
              const minMax = findMinMaxOfEvent(
                row.next,
                (zd) => zd.customDemand
              );
              if (!minMax) return <>--</>;
              else if (minMax.singleValue) return <>{minMax.min}</>;
              else return <>{`${minMax.min} to ${minMax.max}`}</>;
            },
          },
          {
            header: 'Next Date',
            display: ({ row }) => (
              <>{moment.utc(row.next.startDate).format('yyyy-MM-DD')}</>
            ),
          },
          {
            header: 'Other dates',
            flexGrow: 1,
            display: ({ row }) => (
              <OtherDateGrid>
                {row.other.map((ev) =>
                  new Date(ev.endDate).valueOf() - now < -34000000000 ? null : (
                    <EventLink
                      onClick={(evt) => {
                        modalStack.push(
                          <ModEventModal holiday={true} id={ev.dateID!} />
                        );
                        evt.preventDefault();
                      }}
                    >
                      {moment.utc(ev.startDate).format('yyyy-MM-DD')}
                    </EventLink>
                  )
                )}
              </OtherDateGrid>
            ),
          },
        ]}
        rows={holidayRows}
      />*/}
    </Main>
  );
};

export default DemandPage;
