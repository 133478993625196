import Input from 'components/input/Input';
import Label from 'components/input/Label';
import Toggle from 'components/input/Toggle';
import React, { ChangeEvent, FC, useCallback } from 'react';
import { PricingRange } from 'store/zone/reducer';
import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ disabledStyle: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0px 10px 20px;

  ${({ disabledStyle }) =>
    disabledStyle &&
    css`
      color: ${({ theme }) => theme.palette.disabled.fg};
    `}
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  font-weight: bold;
  text-transform: capitalize;
`;

interface Props {
  title: string;
  active: boolean;
  /** if false, 'active' toggle will be hidden */
  toggleable: boolean;
  min: number;
  max: number;
  overlay?: boolean | JSX.Element;
  charge?: PricingRange | { active: false; min: 0; max: 0 };
  onActiveChanged(a: boolean): void;
  onMinChanged(v: number): void;
  onMaxChanged(v: number): void;
}

const PricingRangeInput: FC<Props> = ({
  title,
  active,
  toggleable,
  min,
  max,
  overlay,
  charge = {
    active: false,
    min: 0,
    max: 0,
  },
  onActiveChanged,
  onMinChanged,
  onMaxChanged,
}) => {
  return (
    <Wrapper disabledStyle={!active}>
      <Title>
        {title}
        {toggleable && (
          <Toggle
            checked={active}
            onChange={(eve) => onActiveChanged(eve.target.checked)}
          />
        )}
      </Title>

      <Label
        label="Max"
        hasRightLabel={charge.active}
        rightLabel={`(Incl. EV ${max + charge?.max!})`}
      >
        <Input
          type="number"
          value={max}
          onChange={useCallback(
            (eve: ChangeEvent<HTMLInputElement>) =>
              onMaxChanged(Number(eve.target.value)),
            [onMaxChanged]
          )}
          disabled={!active}
        />
      </Label>
      <Label
        label="Min"
        hasRightLabel={charge.active}
        rightLabel={`(Incl. EV ${min + charge?.min!})`}
      >
        <Input
          type="number"
          value={min}
          onChange={useCallback(
            (eve: ChangeEvent<HTMLInputElement>) =>
              onMinChanged(Number(eve.target.value)),
            [onMinChanged]
          )}
          disabled={!active}
        />
      </Label>
      {overlay}
    </Wrapper>
  );
};

export default PricingRangeInput;
