import React, { createContext, useEffect, useState } from 'react';
import { callbackify } from 'util';

export const makeApiCall = async (url: RequestInfo, options: RequestInit) => {
  var result = await fetch(`/api${url}`, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  var resp = await result.text();
  var json = JSON.parse(resp) as any;
  return json;
};

const getUsers = async () => {
  var userArr = await makeApiCall('/userAdmin/get/users', {
    method: 'GET',
  });
  return userArr as User[];
};

const getRoles = async () => {
  var rolesArr = await makeApiCall('/userAdmin/get/roles', {
    method: 'GET',
  });
  return rolesArr as Role[];
};

const getFacilities = async () => {
  var facArr = await makeApiCall('/userAdmin/get/facilities', {
    method: 'GET',
  });
  return facArr as Facilities[];
};

interface UserAdminProps {
  users: User[];
  roles: Role[];
  facilities: Facilities[];
  updateUser?: (user: User, callback: (resp: any) => void) => void;
  createUser?: (user: Omit<User, 'id'>, callback: (resp: any) => void) => void;
  removeUser?: (userId: string, callback: (success: boolean) => void) => void;
  sendActivationMail: (
    userId: string,
    callback: (success: boolean) => void
  ) => void;
}

const initialContext = {
  users: [],
  roles: [],
  facilities: [],
  sendActivationMail: () => {},
} as UserAdminProps;

const userListSort = (a: User, b: User) => {
  if (a.email > b.email) {
    return -1;
  } else if (a.email < b.email) {
    return 1;
  }
  return 0;
};
export const UserAdminContext = createContext(initialContext);

interface Props {}
const UserAdminWrapper: React.FC<Props> = (props) => {
  const [users, setUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [facilities, setFacilities] = useState<Facilities[]>([]);

  useEffect(() => {
    getUsers().then((users) => {
      setUsers(users.sort(userListSort));
    });

    getRoles().then((roles) => {
      setRoles(roles);
    });

    getFacilities().then((facs) => {
      setFacilities(facs);
    });
  }, []);

  const updateUser = (user: User, callback: (resp: any) => void) => {
    makeApiCall('/userAdmin/updateuser', {
      method: 'POST',
      body: JSON.stringify(user),
    }).then((respUser: { success: boolean; message: string; user: User }) => {
      if (respUser.success) {
        var userList = users.filter((f) => f.id !== respUser.user.id);
        userList.push(respUser.user);
        setUsers(userList.sort(userListSort));
      }
      callback(respUser);
    });
  };
  const removeUser = (userId: string, callback: (success: boolean) => void) => {
    makeApiCall(`/userAdmin/removeuser?userId=${userId}`, {
      method: 'GET',
    }).then((success: boolean) => {
      if (success) {
        setUsers(users.filter((f) => f.id !== userId));
      }
      callback(success);
    });
  };
  const createUser = (
    user: Omit<User, 'id'>,
    callback: (resp: any) => void
  ) => {
    makeApiCall('/userAdmin/createUser', {
      method: 'POST',
      body: JSON.stringify(user),
    }).then((resp: { success: boolean; message: string; user: User }) => {
      if (resp.user != null) {
        setUsers([...users, resp.user].sort(userListSort));
      }

      callback(resp);
    });
  };

  const sendActivationEmail = (
    userId: string,
    callback: (success: boolean) => void
  ) => {
    makeApiCall(`/userAdmin/sendactivationemail?userId=${userId}`, {
      method: 'GET',
    }).then((success: boolean) => {
      callback(success);
    });
  };

  return (
    <UserAdminContext.Provider
      value={{
        users,
        roles,
        facilities,
        updateUser: updateUser,
        removeUser: removeUser,
        createUser: createUser,
        sendActivationMail: sendActivationEmail,
      }}
    >
      {props.children}
    </UserAdminContext.Provider>
  );
};

export default UserAdminWrapper;

export class User {
  /**
   *
   */
  id?: string;
  email: string;
  userName: string;
  activated: boolean;
  roles: Role[];
  facilities: Facilities[];

  constructor(user?: any) {
    if (user) {
      this.id = user.id;
      this.email = user.email;
      this.userName = user.userName;
      this.activated = user.activated;
      this.roles = user.roles;
      this.facilities = user.facilities;
    } else {
      this.email = '';
      this.userName = '';
      this.activated = false;
      this.roles = [];
      this.facilities = [];
    }
  }
}

export class Role {
  id: string;
  name: string;
  constructor(role: any) {
    this.id = role.id;
    this.name = role.name;
  }
}

export class Facilities {
  id: number;
  name: string;
  route: string;
  /**
   *
   */
  constructor(fac: Facilities) {
    this.id = fac.id;
    this.name = fac.name;
    this.route = fac.route;
  }
}
