import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import useModalStack from '../components/Modal/useModalStack';
import HiddenSaveModal from '../components/HiddenSaveModal';
import { addCharToNum } from 'util/range';
import ForecastProjection from 'components/ForecastProjection';

// TODO: Quick Hack, move out graph data instead of using this
const AccRevContext = React.createContext({
  visibleDiff: false,
  oldAccRev: -1,
  accRev: -1,
  setVisibleDiff: ((v: boolean) => {}) as React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setOldAccRev: ((v: number) => {}) as React.Dispatch<
    React.SetStateAction<number>
  >,
  setAccRev: ((v: number) => {}) as React.Dispatch<
    React.SetStateAction<number>
  >,
});
export const AccRevContextProvider: FC = ({ children }) => {
  const [accRev, setAccRev] = useState(-1);
  const [oldAccRev, setOldAccRev] = useState(-1);
  const [visibleDiff, setVisibleDiff] = useState(false);
  return (
    <AccRevContext.Provider
      value={{
        accRev,
        setAccRev,
        oldAccRev,
        setOldAccRev,
        visibleDiff,
        setVisibleDiff,
      }}
    >
      {children}
    </AccRevContext.Provider>
  );
};
export const useAccRevContext = () => useContext(AccRevContext);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 25px;
  text-align: center;
  line-height: 12px;
  font-size: 16px;
  font-weight: 700;
  min-width: 80px;
`;

const Num = styled.span`
  line-height: 25px;
  margin-top: 10px;
  font-size: 2em;
  font-weight: 300;
`;

function shortenRev(revenue: number) {
  // round to display 3 digits in total
  // const r = (n: number) => {
  //   if (n >= 100) {
  //     return Math.round(n);
  //   } else if (n >= 10) {
  //     return Math.round(n * 10) / 10;
  //   }
  //   return Math.round(n * 100) / 100;
  // };

  // if (revenue >= 1000000000000) {
  //   return `${r(revenue / 1000000000000)}T`;
  // } else if (revenue >= 1000000000) {
  //   return `${r(revenue / 1000000000)}B`;
  // } else if (revenue >= 1000000) {
  //   return `${r(revenue / 1000000)}M`;
  // } else if (revenue >= 1000) {
  //   return `${r(revenue / 1000)}K`;
  // }
  return addCharToNum(`${revenue}`, ',');
}

interface Props {}

const AccRevenueSticker: FC<Props> = ({}) => {
  const modalStack = useModalStack();
  const { accRev, oldAccRev, visibleDiff } = useAccRevContext();
  if (accRev <= 0) return null;
  return (
    <Wrapper
      onClick={(evt) => {
        if (evt.shiftKey) {
          modalStack.push(<HiddenSaveModal />);
        }
      }}
    >
      <span style={{ minWidth: '125px' }}>Revenue forecast</span>{' '}
      <Num>{shortenRev(accRev)}</Num>
      {visibleDiff && (
        <ForecastProjection
          value={Math.round(100 * (accRev / oldAccRev - 1))}
        />
      )}
    </Wrapper>
  );
};

export default AccRevenueSticker;
