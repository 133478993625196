import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div``;

const RowBase = styled.div<{ columns: ColumnSetting<any>[] }>`
  display: flex;
  flex-direction: row;

  ${({ columns }) =>
    columns.map(
      (column, index) => css`
        & > *:nth-child(${index + 1}) {
          flex: ${column.flexGrow ?? 1};
          min-width: ${column.minWidth ?? '0'};
          padding: 15px 0px;
        }
      `
    )}
`;

const Row = styled(RowBase)`
  &:not(:last-child) {
    border-bottom: 1px solid;
    border-color: inherit;
  }
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey.bg};
  }
`;

const HeaderRow = styled(RowBase)`
  font-weight: bold;
  border-bottom: 2px solid;
  border-color: inherit;
`;

export interface ColumnSetting<O extends Object> {
  header: string;
  /** attribute name of row-object or render function for row-object */
  display: keyof O | FC<{ row: O }>;
  /** defaults to 1 */
  flexGrow?: number;
  /** css string */
  minWidth?: string;
}

interface Props<O> {
  rows: O[];
  columnSettings: ColumnSetting<O>[];
}

const List = <O extends object>({ rows, columnSettings }: Props<O>) => {
  return (
    <Wrapper>
      <HeaderRow columns={columnSettings}>
        {columnSettings.map((column, colIndex) => (
          <div key={colIndex}>{column.header}</div>
        ))}
      </HeaderRow>

      {rows.map((row, rowIndex) => (
        <Row columns={columnSettings} key={rowIndex}>
          {columnSettings.map((column, colIndex) => (
            <div key={colIndex}>
              {typeof column.display === 'function'
                ? column.display({ row })
                : row[column.display]}
            </div>
          ))}
        </Row>
      ))}
    </Wrapper>
  );
};

export default List;
