import React, { FC, useEffect, useState } from 'react';
import List from 'components/List';
import Main from 'components/Main';
import useModalStack from 'components/Modal/useModalStack';
import { useDispatch, useSelector } from 'react-redux';
import {
  zoneStoreAddPrice,
  zoneStoreApply,
  zoneStoreModPrice,
} from 'store/zone/action';
import {
  selectAvailableFacilityZones,
  selectCurrentZone,
} from 'store/zone/selector';
import {
  Pricing,
  PricingRange,
  Zone,
  ZoneFlag,
} from '../../store/zone/reducer';
import NavBar from 'components/NavBar';
import AddPriceProductModal from './AddPriceProductModal';
import SubHeader from 'components/SubHeader';
import Button, { ButtonColors } from 'components/input/Button';
import PricingParams from './PricingParams';
import PricingParamsGraph from './PricingParamsGraph';
import Radio from 'components/input/Radio';
import ModPriceProductModal from './ModPriceProductModal';
import DeleteButton from 'components/input/DeleteButton';
import RemPriceProductModal from './RemPriceProductModal';
import ZoneWrapper from './ZoneWrapper';
import { PriceV3Api } from 'constants/api-fetch';
import { clearCacheUpdate } from 'store/calcCache/action';
import { PriceProductExport } from 'store/uiSettings/reducer';
import Modal from 'components/Modal';
import Select from 'components/input/Select';
import styled from 'styled-components';
import {
  durationSort,
  generatePriceRange,
  getDurationTitle,
  getSort,
} from 'util/pricing';

export const PricingRangeComponent: React.FC<{
  range?: PricingRange;
  chargeRange?: { min: number; max: number };
}> = ({ range, chargeRange }) => {
  if (!range) return <span style={{ opacity: 0.35 }}>[NA]</span>;
  return (
    <>
      <span> {range.min + ' - ' + range.max} </span>
      {chargeRange && (
        <span>
          ({range.min + chargeRange.min + ' - ' + (range.max + chargeRange.max)}
          )
        </span>
      )}
    </>
  );
};

interface Segment {
  header: string;
  duration: PricingRange['duration'];
  sort: number;
  [key: number]: {
    id: number;
    groupId?: number | null;
    duration: string;
  };
}

export default (() => {
  const modalStack = useModalStack();

  const dispatch = useDispatch();
  const zone = useSelector(selectCurrentZone);
  const allZones = useSelector(selectAvailableFacilityZones);
  const [isEvCharge, setIsEvCharge] = useState(
    !!zone?.zoneFlags?.find((f) => f === ZoneFlag.Electric)
  );

  useEffect(() => {
    setIsEvCharge(!!zone?.zoneFlags?.find((f) => f === ZoneFlag.Electric));
  }, [zone]);

  const getExports = () => {
    const arr: PriceProductExport[] = [];
    allZones.forEach((zone) => {
      zone.exports.forEach((exp) => {
        exp.zoneName = zone.name;
        exp.priceName = zone.pricings.find(
          (price) => price.id === exp.pricingSourceID
        )?.name;
        arr.push(exp);
      });
    });
    return arr;
  };
  const pricings = zone ? zone.pricings : [];

  const [segment, onSetSegment] = useState('driveup');
  const [exports, setExports] = useState(getExports());

  var durationList = allZones
    .reduce((acc, curr) => {
      var durations = curr.pricings.flatMap((f) => {
        return f.prices
          .filter((f) => f.segment === segment && f.active)
          .map((x) => x.duration);
      });
      durations.forEach((x) => {
        if (acc.indexOf(x) === -1) {
          acc.push(x);
        }
      });
      return acc;
    }, [] as string[])
    .sort((a, b) => durationSort(a) - durationSort(b));

  const [duration, setDuration] = useState(
    durationList.length > 0 ? durationList[0] : 'hour'
  );
  useEffect(() => {
    if (durationList.length > 0 && durationList.indexOf(duration) === -1) {
      setDuration(durationList[0]);
    }
  }, [durationList]);

  var zonePricingList: Segment[] = [];
  if (zone) {
    zonePricingList = zone.pricings.reduce((acc, pricing) => {
      var _zonePrices: Segment[] = pricing.prices
        .filter((f) => f.segment === segment)
        .reduce((acc, curr) => {
          if (curr.groupId != null) {
            var group = pricing?.priceGroups.find((x) => x.id === curr.groupId);
            return [
              ...acc,
              {
                header: `${group?.name} span`,
                duration: curr.duration,
                sort: getSort(curr),
                [pricing.id]: {
                  id: curr.id,
                  duration: curr.duration.toString(),
                  groupId: curr.groupId,
                },
              },
            ];
          }
          return [
            ...acc,
            {
              sort: getSort(curr),
              duration: curr.duration,
              header: `${getDurationTitle(curr.duration)} span`,
              [pricing.id]: {
                id: curr.id,
                duration: curr.duration.toString(),
                groupId: curr.groupId,
              },
            },
          ];
        }, [] as Segment[]);
      _zonePrices.forEach((curr) => {
        var innerPricing = acc.find((f) => f.header == curr.header);
        if (!innerPricing) {
          acc.push(curr);
        } else {
          innerPricing[pricing.id] = curr[pricing.id];
        }
      });
      return acc;
    }, [] as Segment[]);
  }

  const showPricingModal = (id: number, zone?: Zone) => {
    const isLive = exports.find((x) => x.pricingSourceID === id);
    if (isLive !== undefined) {
      modalStack.push(IsLockedModal);
    } else {
      modalStack.push(
        <ModPriceProductModal
          priceId={id}
          zone={zone}
          onModPricing={(pricing) => {
            dispatch(zoneStoreModPrice(pricing));
          }}
        />
      );
    }
  };

  const showExportModal = (id: number) => {
    const exp = exports.find((x) => x.id === id);
    const priceList = allZones.find(
      (x) => x.id === exp?.sourceDZoneID
    )?.pricings;

    modalStack.push(
      <SelectExportPriceComponent
        export={exp}
        pricings={priceList}
        onEditExport={(updatedExport) => {
          const list = getExports();
          const existing = list.find((x) => x.id === updatedExport.id);
          existing!.dateModified = updatedExport.dateModified;
          existing!.pricingSourceID = updatedExport.pricingSourceID;
          setExports(list);
        }}
      />
    );
  };

  return (
    <Main>
      <NavBar />

      <PricingParams
        durationParams={durationList}
        onChangeDuration={setDuration}
        onChangeSegment={onSetSegment}
        hasZone={!!zone}
      />
      {!zone && (
        <>
          <PricingParamsGraph segment={segment} duration={duration} />
        </>
      )}

      {zone && (
        <>
          <PricingParamsGraph
            segment={segment}
            duration={duration}
            zone={zone}
          />
          {/* <GraphParams />
          <Graph kind="pricing" /> */}
        </>
      )}
      <SubHeader
        buttons={
          zone && (
            <Button
              color={ButtonColors.add}
              onClick={() =>
                modalStack.push(
                  <AddPriceProductModal
                    onAddPricing={(pricing) => {
                      dispatch(zoneStoreAddPrice(pricing));
                      //alert('TODO: add pricing');
                      console.log('added pricing?', pricing);
                    }}
                  />
                )
              }
            >
              Add
            </Button>
          )
        }
      >
        Price products
      </SubHeader>
      {!zone && (
        <ZoneWrapper
          showPricingModal={showPricingModal}
          zones={allZones}
          segment={segment}
          duration={duration}
        />
      )}
      {zone && (
        <List
          columnSettings={[
            {
              header: '',
              flexGrow: 0,
              minWidth: '30px',
              display: ({ row }) => {
                // { ...(?{"checked":true}:{} )  }
                return (
                  <Radio
                    name="zoneprisel"
                    value={row.id}
                    checked={row.id === zone.selectedPricing}
                    onChange={(evt) => {
                      if (evt.currentTarget.checked) {
                        var api = new PriceV3Api();
                        api
                          .put(
                            `zone/${zone.id}/setselectedpricing/${row.id}`,
                            JSON.stringify({})
                          )
                          .then((res: boolean) => {
                            if (res) {
                              dispatch(clearCacheUpdate());
                              dispatch(
                                zoneStoreApply(zone.id, {
                                  selectedPricing: row.id,
                                })
                              );
                            }
                          });
                      }
                    }}
                  />
                );
              },
            },
            {
              header: 'Name',
              display: ({ row }) => {
                return (
                  <Link
                    onClick={(evt) => {
                      showPricingModal(row.id);
                      evt.preventDefault();
                    }}
                  >
                    {row.name}
                  </Link>
                );
              },
            },
            ...zonePricingList
              .sort((a, b) => a.sort - b.sort)
              .map((x) => {
                return {
                  header: x.header,
                  display: ({ row }: { row: Pricing }) => {
                    var value = x[row.id];
                    if (!value)
                      return <PricingRangeComponent range={undefined} />;

                    var { range, chargeRange } = generatePriceRange(
                      row.prices.find((f) => f.id === value.id),
                      x.duration,
                      isEvCharge
                        ? row.prices.filter((f) => f.segment === 'surcharge')
                        : []
                    );
                    return (
                      <PricingRangeComponent
                        range={range}
                        chargeRange={chargeRange}
                      />
                    );
                  },
                };
              }),
            // {
            //   header: 'Hour span',
            //   display: ({ row }) => (
            //     <PricingRangeComponent
            //       range={row.prices.find(
            //         (pric) => pric.duration === 'hour' && pric.active
            //       )}
            //     />
            //   ),
            // },
            // {
            //   header: 'Day span',
            //   display: ({ row }) => (
            //     <PricingRangeComponent
            //       range={row.prices.find(
            //         (pric) => pric.duration === 'day' && pric.active
            //       )}
            //     />
            //   ),
            // },
            // {
            //   header: 'Week span',
            //   display: ({ row }) => (
            //     <PricingRangeComponent
            //       range={row.prices.find(
            //         (pric) => pric.duration === 'week' && pric.active
            //       )}
            //     />
            //   ),
            // },
            {
              header: '',
              flexGrow: 0,
              minWidth: '50px',
              display: ({ row }) =>
                (row.id !== zone.selectedPricing && (
                  <DeleteButton
                    onClick={(evt) => {
                      const isLive = exports.find(
                        (x) => x.pricingSourceID === row.id
                      );
                      if (isLive !== undefined) {
                        modalStack.push(IsLockedModal);
                      } else {
                        modalStack.push(
                          <RemPriceProductModal
                            zoneId={zone.id}
                            priceId={row.id}
                          />
                        );
                      }
                      evt.preventDefault();
                    }}
                  />
                )) as JSX.Element,
            },
          ]}
          rows={pricings}
        />
      )}
      <SubHeader buttons="">Live Prices</SubHeader>
      <List
        columnSettings={[
          {
            header: 'Zone Name',
            display: ({ row }) => {
              return (
                <Link
                  onClick={(evt) => {
                    showExportModal(row.id);
                    evt.preventDefault();
                  }}
                >
                  {row.zoneName}{' '}
                  {row.exporterType === 'TwoParkQA' ? '(QA)' : ''}
                </Link>
              );
            },
          },
          {
            header: 'Target product',
            display: ({ row }) => <div>{row.exporterTargetID}</div>,
          },
          {
            header: 'Pricing product',
            display: ({ row }) => <div>{row.priceName}</div>,
          },
          {
            header: 'Last Modified',
            display: ({ row }) => (
              <div>{row.dateModified.toString().slice(0, 10)}</div>
            ),
          },
          {
            header: 'Status',
            display: ({ row }) => (
              <span>
                {row.pricingSourceID !== null &&
                row.pricingSourceID !== undefined
                  ? 'Active'
                  : 'Inactive'}
              </span>
            ),
          },
        ]}
        rows={exports.sort((a, b) => {
          if (
            !isNaN(Number(a.exporterTargetID)) &&
            isNaN(Number(b.exporterTargetID))
          ) {
            return 1;
          }
          if (
            !isNaN(Number(b.exporterTargetID)) &&
            isNaN(Number(a.exporterTargetID))
          ) {
            return -1;
          }
          if (a.exporterTargetID.localeCompare(b.exporterTargetID) !== 0) {
            return a.exporterTargetID.localeCompare(b.exporterTargetID);
          }
          return a.zoneName!.localeCompare(b.zoneName!);
        })}
      />
    </Main>
  );
  //    return <div>Hoppsan</div>;
}) as React.FC;

const ExportLeft = styled.div`
  display: inline-block;
  width: 50%;
  padding: 3px 0;
`;
const ExportRight = styled.div`
  display: inline-block;
  width: 50%;
  padding: 3px 0;
`;
const ExportWrapper = styled.div`
  width: 100%;
`;
const Link = styled.div`
  color: ${({ theme }) => theme.palette.primary.bg};
  font-weight: bold;
  :hover {
    color: rgb(137, 87, 160);
    -webkit-text-decoration: underline;
    text-decoration: underline;
    -webkit-text-decoration-color: rgb(117, 210, 207);
    text-decoration-color: rgb(117, 210, 207);
    -webkit-text-decoration-thickness: 2px;
    text-decoration-thickness: 2px;
    text-underline-position: under;
    cursor: pointer;
  }
`;

interface Props {
  onEditExport: (exp: PriceProductExport) => void;
  export: PriceProductExport | undefined;
  pricings: Pricing[] | undefined;
}

const IsLockedModal = (
  <Modal
    title="Price Product is Locked"
    buttons={[
      {
        label: 'OK',
        onClick: () => {},
      },
    ]}
  >
    <ExportWrapper>
      <div style={{ margin: '20px 0' }}>
        <div>This Price Product is locked while export is activated.</div>
        <div>
          To make changes preferably make a copy of the price or disable
          exporting temporarily.
        </div>
      </div>
    </ExportWrapper>
  </Modal>
);

const SelectExportPriceComponent: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const [selectedPricingID, setSelectedPricing] = useState(
    props.export!.pricingSourceID
  );
  const [selectedPricingName, setSelectedPricingName] = useState(
    props.export!.priceName
  );
  const [active, setActive] = useState(
    selectedPricingID !== null && selectedPricingID !== undefined
  );

  console.log(`PRexp -- ${props.export?.exporterTargetID}`, props.export);

  return (
    <Modal
      title="Update Live exports"
      buttons={[
        {
          label: 'Save',
          onClick: () => {
            var api = new PriceV3Api();
            props.export!.pricingSourceID = selectedPricingID;
            api
              .post('update/priceexport', JSON.stringify(props.export))
              .then((res: PriceProductExport) => {
                if (res !== null) {
                  dispatch(clearCacheUpdate());
                  props.onEditExport(res);
                }
              });
          },
        },
        {
          label: 'Cancel',
          onClick: () => {},
        },
      ]}
    >
      <ExportWrapper>
        <div style={{ margin: '20px 0' }}>
          Select a pricelist which will be exported to Autopay every night.
          <br />
          Select "None" to deactivate
        </div>
        <ExportLeft>Zone</ExportLeft>
        <ExportRight>{props.export!.zoneName}</ExportRight>
        <ExportLeft>Pricing product</ExportLeft>
        <ExportRight>{selectedPricingName}</ExportRight>
        <ExportLeft>Status</ExportLeft>
        <ExportRight>{active ? 'Active' : 'Inactive'}</ExportRight>
        <ExportLeft>Target Product ID</ExportLeft>
        <ExportRight>
          {props.export?.exporterTargetID || ''}
          {props.export?.exporterType === 'TwoParkQA' ? '(QA)' : ''}
        </ExportRight>
        <ExportLeft>Credentials</ExportLeft>
        <ExportRight
          style={
            props.export?.exporterTargetApiCredentialsID
              ? undefined
              : { color: 'RED' }
          }
        >
          {props.export?.exporterTargetApiCredentialsID ? 'Active' : 'Missing'}
        </ExportRight>

        <div style={{ marginTop: '20px' }}>
          <Select
            onChange={(evt) => {
              const priceId = parseInt(evt.target.value);
              let name = undefined;
              let active = false;
              if (priceId !== -1) {
                name = props.pricings!.find(
                  (price) => price.id === priceId
                )?.name;
                active = true;
                setSelectedPricing(priceId);
              } else {
                setSelectedPricing(undefined);
              }
              setSelectedPricingName(name);
              setActive(active);
            }}
            value={selectedPricingID}
          >
            <option value={-1}>None</option>;
            {props.pricings!.map((price) => {
              return (
                <option key={price.id} value={price.id}>
                  {price.name}
                </option>
              );
            })}
          </Select>
        </div>
      </ExportWrapper>
    </Modal>
  );
};
