import moment from 'moment';
import { Price_Resp } from '../../store/calcCache/definitions';
import {
  GraphData,
  GraphDataBundle,
  GraphPoint,
  SegmentGraphStat,
} from './defs';
import { Event } from 'store/event/definitions';
import { CompareDataSet } from 'store/compareCache/definitions';

const possibleSegments = ['driveup', 'booking', 'total'] as const;

const dummyZeroCompareEntry = { revenue: 0 };

let emptyNum = [] as number[];

export const dataZip = (
  zones: Price_Resp['zones'],
  segment: 'all' | 'driveup' | 'booking',
  //priceType: 'hour' | 'day' | 'week' | 'hour_avg',
  selectedPriceViewTypeId: string,
  accumulatedHourCount: number,
  firstStep: number,
  zoneId?: number,
  comparedTo?: CompareDataSet | null
) => {
  //console.log('SelectedPriceViewTID:', selectedPriceViewTypeId);
  console.log('Zip begin for seg:', segment);

  const usedSegments = possibleSegments.filter(
    (ps) => segment === 'all' || segment === ps
  );

  // The input zones are dynamic zones separated by segment (ie more like a list of zone-segment informations)
  // Filter out non-selected zones and segments. (unless we have all, either byh segment=="all" or zoneId===undefined)
  const actZones = (zones || [])
    .filter((zn) => {
      if (zoneId != undefined) {
        if (zn.zoneId !== zoneId.toString()) {
          return false;
        }
      }
      if (segment === 'all') return true;
      return segment === zn.kind;
    })
    .map((zn) => ({ ...zn, segmentIndex: usedSegments.indexOf(zn.kind) }));

  // create zone accumulators
  const zoneAccs = actZones.map((zn) => ({
    demAcc: 0,
    odemAcc: 0,
    scale: zn.year.estiatedCustomerGrowthPercentage / 100 + 1,
  }));

  const zoneComparesData = !comparedTo
    ? []
    : actZones.map((az) => comparedTo?.dataByZone[az.zoneId]);

  //const realZoneCount = new Set(zones.map((z) => z.zoneid)).size;
  const realZoneCount = 1;

  // const bookings = (zones || []).filter(
  //   (zn) => zn.kind === 'booking' && segment !== 'driveup'
  // );
  // const driveups = (zones || []).filter(
  //   (zn) => zn.kind === 'driveup' && segment !== 'booking'
  // );

  // Take the max count from all zones (some zones might have less data for various reasons)
  const count = actZones.reduce(
    (acc, cur) => Math.max(acc, cur.hours.length),
    0
  );
  // const count = Math.max(
  //   bookings
  //     .map((booking) => booking.hours.length)
  //     .reduce((a, b) => Math.max(a, b), 0),
  //   driveups.map((du) => du.hours.length).reduce((a, b) => Math.max(a, b), 0)
  // );

  const soAccRev = usedSegments.map((s) => 0);
  const snAccRev = usedSegments.map((s) => 0);
  const sdAccRev = usedSegments.map((s) => 0);
  // let boAccRev = 0;
  // let doAccRev = 0;
  let toAccRev = 0;
  // let bnAccRev = 0;
  // let dnAccRev = 0;
  let tnAccRev = 0;
  let tdAccRev = 0;

  let comAccRev = 0;

  type ZoneInfo = Price_Resp['zones'][number];
  type HourEntry = ZoneInfo['hours'][number];

  let demandAcc = 0;
  let oldDemandAcc = 0;

  const data = [] as GraphPoint[];

  let hasBooking = false;
  let hasDriveup = false;

  let span = firstStep;

  //console.log('First timestamp:', typeof actZones[0].hours[0].time);

  let compSkip = 0;
  if (comparedTo && actZones.length > 0 && actZones[0].hours.length > 0) {
    compSkip = moment
      .utc(actZones[0].hours[0].time)
      .diff(moment.utc(comparedTo.viewStartDate), 'hours');
    console.log(
      `Between cache:${comparedTo.viewStartDate} view:${actZones[0].hours[0].time} we need to skip ${compSkip} hours`
    );
  }

  // console.log(
  //   'SeriesX:',
  //   actZones.map((az) =>
  //     az.series.map((ser) => [
  //       `${az.zoneId} ${az}-${az.kind} => ${ser.category} ${ser.title} ${ser.sourceID}`,
  //       ser,
  //     ])
  //   )
  // );

  const seriesIDs = [
    ...actZones
      .flatMap((az) =>
        az.series
          .filter((s) => s.stepInSeconds / 60 / 60 <= accumulatedHourCount)
          .map((s) => s.outID)
      )
      // this last part makes the list unique
      .reduce((a, c) => (a.add(c), a), new Set<string>()),
  ];
  const npriceIndex = seriesIDs.findIndex(
    (sid) => sid === selectedPriceViewTypeId
  );

  //console.log('UniqueSeries:', seriesIDs);

  let prevOn: GraphPoint | null = null;
  // go through data in acc increments
  for (let i = 0; i < count; i += span) {
    const timeValue = actZones[0].hours[i].time; // (bookings?.hours[i].time || driveups?.hours[i].time)!;
    const outerDate = new Date(timeValue);
    const currentEntryStepSize = span;

    // Only use first step on the first iteration, used to handle not compmlete first week of the year
    if (i >= firstStep) {
      span = accumulatedHourCount;
    }

    const mk0Revenue = () => ({ current: 0, accumulated: 0 });

    const on: GraphPoint = {
      tick: moment(span >= 24 ? timeValue.substring(0, 10) : timeValue)
        .utc()
        .toISOString(),
      odate: timeValue,
      date: span >= 24 ? timeValue.substring(0, 10) : timeValue,

      demand: 0,

      // newRevenue: mk0Revenue(),
      // oldRevenue: mk0Revenue(),
      // overviewRevenue: mk0Revenue(),
      segments: usedSegments.map((s) => ({
        demand: 0,
        newRevenue: mk0Revenue(),
        oldRevenue: mk0Revenue(),
        defRevenue: mk0Revenue(),
      })),

      seriesID: seriesIDs,
      series: seriesIDs.map((s) => 0),
      seriesAcc: seriesIDs.map((s) => 0),

      // // demand
      // boDemand: 0,
      // duDemand: 0,

      // // old revenue
      // boRevenue: 0,
      // doRevenue: 0,
      toRevenue: 0,
      // // accumulated revenue
      // boAccRev: 0,
      // doAccRev: 0,
      toAccRev: 0,
      // bnRevenue: 0,
      // dnRevenue: 0,
      tnRevenue: 0,
      // bnAccRev: 0,
      // dnAccRev: 0,
      tnAccRev: 0,
      // default revenue
      tdRevenue: 0,
      tdAccRev: 0,

      comRevenue: 0,
      comAccRev: 0,

      price: 0,
      isDemandPrediction: false,

      dateMonth: outerDate.getMonth(),
      dateDay: outerDate.getDay(),
    };

    let spanCount = Math.min(currentEntryStepSize, count - i); // we need the span-count to adjust for end-of-year weeks that don't span an entire week.
    // go through all hours within this span
    for (let j = 0; j < spanCount; j++) {
      // and then for each zone.
      for (let k = 0; k < actZones.length; k++) {
        const zone = actZones[k];
        const zoneAcc = zoneAccs[k];

        // don't try to accumulate out-of-span hours.
        if (i + j >= zone.hours.length) continue;
        var seriesFinalAcc: {
          electric: {
            [key: string]: number;
          };
        } = {
          electric: {},
        };
        for (let l = 0; l < seriesIDs.length; l++) {
          let seriesData: typeof zone.series[0] = null as any;
          // Find if this datapoint has something matching the "global" seriesID (we could for example have non-electric and electric zones.. only electric zones would have electric surcharges)
          for (let m = 0; m < zone.series.length; m++) {
            if (zone.series[m].outID !== seriesIDs[l]) continue;
            seriesData = zone.series[m];
          }
          if (!seriesData) continue;

          // Only sample as many samples as we have available.
          let seriesStepInHours = seriesData.stepInSeconds / 60 / 60;
          if (0 !== (i + j) % seriesStepInHours) {
            continue;
          }

          // The entry in the series we will currently index depends on the step-size
          let seriesIdx = (i + j) / seriesStepInHours;
          switch (seriesData.compacting) {
            case 'ACCUMULATE':
              {
                on.series[l] += seriesData.values[seriesIdx];
                on.seriesAcc[l] = (prevOn?.seriesAcc[l] ?? 0) + on.series[l];
                seriesFinalAcc[
                  seriesData.category as keyof typeof seriesFinalAcc
                ][seriesData.valueKind] = on.seriesAcc[l];
              }
              break;
            case 'AVERAGE':
              {
                on.series[l] +=
                  seriesData.values[seriesIdx] /
                  (spanCount / seriesStepInHours);
                on.seriesAcc[l] = (prevOn?.seriesAcc[l] ?? 0) + on.series[l];
              }
              break;
            default:
              console.log(seriesData.compacting);
              break;
          }
          // = zone.series.find(zs=>zs.outID===seriesIDs[l]);
        }

        const seriesEvTotalRevenue = seriesFinalAcc.electric?.revenue ?? 0;
        // hourentry is valid (within the span)
        const hourEntry = zone.hours[i + j];

        const compIndex = compSkip + i + j;
        const compEntry =
          !comparedTo ||
          compIndex < 0 ||
          compIndex >= zoneComparesData[k].length
            ? dummyZeroCompareEntry
            : zoneComparesData[k][compIndex];

        // if any hour within the output span is an hour prediction we mark all of them
        on.isDemandPrediction =
          on.isDemandPrediction || hourEntry.isDemandPrediction;

        on.price = on.series[npriceIndex];

        if (zone.kind === 'booking') {
          hasBooking = true;

          // on.boDemand += hourEntry.parkings;
          // //on.duhPrice += hourEntry.price * 60;
          // on.bnRevenue += hourEntry.newRevenue;
          // bnAccRev += hourEntry.newRevenue;
          // on.bnAccRev = Math.floor(bnAccRev);

          // on.boRevenue += hourEntry.oldRevenue;
          // boAccRev += hourEntry.oldRevenue;
          // on.boAccRev = Math.floor(boAccRev);
        } else if (zone.kind === 'driveup') {
          hasDriveup = true;

          // on.duDemand += hourEntry.parkings;

          // on.dnRevenue += hourEntry.newRevenue;
          // dnAccRev += hourEntry.newRevenue;
          // on.dnAccRev = Math.floor(dnAccRev);

          // on.doRevenue += hourEntry.oldRevenue;
          // doAccRev += hourEntry.oldRevenue;
          // on.doAccRev = Math.floor(doAccRev);
        }

        on.segments[zone.segmentIndex].demand += hourEntry.parkings;
        on.demand += hourEntry.parkings;

        demandAcc += hourEntry.parkings;
        oldDemandAcc += hourEntry.parkingsBase;

        zoneAcc.demAcc += hourEntry.parkings * zoneAcc.scale;
        zoneAcc.odemAcc += hourEntry.parkingsBase;

        on.segments[zone.segmentIndex].newRevenue.current +=
          hourEntry.newRevenue;
        on.tnRevenue += hourEntry.newRevenue;
        snAccRev[zone.segmentIndex] += hourEntry.newRevenue;
        tnAccRev += hourEntry.newRevenue;
        on.segments[zone.segmentIndex].newRevenue.accumulated = Math.floor(
          snAccRev[zone.segmentIndex]
        );
        on.tnAccRev = Math.floor(tnAccRev);

        on.segments[zone.segmentIndex].oldRevenue.current +=
          hourEntry.oldRevenue;
        on.toRevenue += hourEntry.oldRevenue;
        soAccRev[zone.segmentIndex] += hourEntry.oldRevenue;
        toAccRev += hourEntry.oldRevenue;
        on.segments[zone.segmentIndex].oldRevenue.accumulated = Math.floor(
          soAccRev[zone.segmentIndex]
        );
        on.toAccRev = Math.floor(toAccRev);

        const defRevenue = hourEntry.isDemandPrediction
          ? hourEntry.newRevenue
          : hourEntry.oldRevenue;

        on.segments[zone.segmentIndex].defRevenue.current += defRevenue;
        on.tdRevenue += defRevenue;
        sdAccRev[zone.segmentIndex] += defRevenue;
        tdAccRev += defRevenue;
        on.segments[zone.segmentIndex].defRevenue.accumulated =
          Math.floor(sdAccRev[zone.segmentIndex]) +
          Math.floor(seriesEvTotalRevenue);
        on.tdAccRev = Math.floor(tdAccRev) + Math.floor(seriesEvTotalRevenue);

        on.comRevenue += compEntry.revenue;
        comAccRev += compEntry.revenue;
        on.comAccRev = Math.floor(comAccRev);
      }
    }
    on.demand = Math.floor(on.demand);
    prevOn = on;
    data.push(on);

    // date: today.add(idx, 'd').toISOString(),
    // duDemand: Math.floor(Math.random() * zone.aggressivity),
    // boDemand: Math.floor(Math.random() * zone.aggressivity * 1.3),
    // price: Math.floor(Math.random() * 3000) / 10,
  }

  //console.log('Zip complete');

  let oldDiv = zoneAccs.reduce((prev, za) => prev + za.odemAcc, 0);
  if (oldDiv < 0.0000000001) oldDiv = 1;
  const custAccDiff =
    zoneAccs.reduce((prev, za) => prev + za.demAcc, 0) / oldDiv;

  //console.log({ oldDiv, zoneAccs, zones });

  const ov = {
    data,
    bookings: hasBooking,
    driveups: hasDriveup,
    realZoneCount,
    demandAcc,
    oldDemandAcc,
    custAccDiff,
    segments: usedSegments,
    seriesIDs: seriesIDs,
  } as GraphData;

  console.log('Zip result:', ov);

  return ov;
};

export const findGraphLevel = (v: number) => {
  for (let i = 0; i < 2000000; i++) {
    const step = 3;
    const maxDig = [1, 2, 5][i % step];
    const maxExp = Math.pow(10, Math.floor(i / step));
    const tMax = maxExp * maxDig;
    if (v < tMax) return tMax;
  }
  return 1;
};

// this takes the highest digit, increases by one
export const roundUpMax = (v: number) => {
  // start by making a ceiled str of the number
  let str = '' + Math.ceil(v);
  // if it starts with a 9 (like in 90 smth, or 900 smth,etc, we append a 0 so that we've bumped the number of digits)
  if (str.startsWith('9')) str = '0' + str;
  // now split and rebuild the string so that everything but the first digit is cleared and the first one bumped.
  return parseInt(
    str
      .split('')
      .map((v, i) => {
        return i === 0 ? parseInt(v) + 1 + '' : '0';
      })
      .join('')
  );
};

export const firstToUpper = (v: string) => {
  if (v.length < 1) return v;
  return v.substring(0, 1).toUpperCase() + v.substring(1);
};

export const numToSpacedString = (v: number) => {
  let out = '';
  if (v >= 1000000) {
    return Math.floor(v / 100000) / 10 + ' M';
  }
  if (v >= 1000) {
    return Math.floor(v / 100) / 10 + ' K';
  }
  return Math.floor(v) + out;

  // while (v >= 1000) {
  //   let low = '' + (v % 10);
  //   let mid = '' + Math.floor(Math.floor(v / 10) % 10);
  //   let high = '' + Math.floor(Math.floor(v / 100) % 10);
  //   out = out + ' ' + high + mid + low;
  //   v = Math.floor(v / 1000);
  // }
  // return v + out;
};

export const isSpecialDay = (date: Date, data: GraphDataBundle) => {
  if (!data.events) return { eventExists: false, event: null };
  //console.log('Facility-check');
  var event = data.events.reduce((acc, ev) => {
    let sd = moment.utc(ev.startDate).unix(); //  new Date(ev.startDate);
    let ed = moment.utc(ev.endDate).unix(); //new Date(ev.endDate);
    let odate = moment.utc(date).unix(); //new Date(date);
    // zoneId , routeId in ev
    // if data.zone , then data.zone.id , data.facility needs id
    if (sd <= odate && odate <= ed) {
      if (
        // for viewing a zone there are 2 disrtinct cases
        (data.zone &&
          // Either it's a facility-wide event, in that case it's enough to be in the correct one.
          ((ev.routeData && ev.routeID === data.facility.id) ||
            // .. or if it's a event for specific zones then that zone must be included!
            (ev.zoneData &&
              ev.zoneData.some((zd) => zd.zoneID === data.zone.id)))) ||
        // If we're viewing all zones and the facility ID matches then we're showing this event
        (!data.zone && ev.routeID === data.facility.id)
      ) {
        acc.push(ev);
      }
    }
    return acc;
  }, [] as Event[]);
  if (event.length > 0) return { eventExists: true, event };
  return { eventExists: false, event: null };
};
