import {
  Pricing,
  PricingDiscount,
  PricingGroup,
  PricingRange,
  Zone,
} from '../zone/reducer';
import { Price_Resp } from '../calcCache/definitions';
import { range } from 'util/range';

// A request for pricing information sent to the backend service
export type Price_Req = {
  calcStartDate: string; // inclusive, "yyyy-MM-dd hh:mm" , UTC, should be specified at midnight
  calcEndDate: string; // exclusive, "yyyy-MM-dd hh:mm" , UTC, should be specified at midnight

  // an array of pricing (one pricing level per zone for the time being)
  pricing: {
    name: string;
    kind: 'driveup' | 'booking' | 'total';
    // zone the price belongs to
    facilityId: string;
    zoneId: string;
    // how many minutes before billing starts
    //minimumBillingMinutes: number;

    // minute min/max pricing levels
    minuteMin: number;
    minuteMax: number;
    aggressivity: number;

    // is there daily or daily and weekly max prices.
    maxType: 'daily' | 'weekly';

    dailyMin: number;
    dailyMax: number;

    weeklyMin?: number;
    weeklyMax?: number;

    ranges: PricingRange[];

    rangeGroups: PricingGroup[];
    discounts: PricingDiscount[];
    // an array of events that modify demand of parking
    // Events are defined as DB-items now... it MIGHT change in the future but uncertain.
    // events: {
    //   active: boolean; // is this event activated
    //   name: string; // user-selected
    //   kind: string; // loose selection of types such as strike, holiday, conference, sport, closed, repairs, etc
    //   demand: number; // percentage, 100 as base demand
    //   fullDays: boolean; // all days within the time-span are included (future UI will switch over to )
    //   startDate: string; // inclusive "yyyy-MM-dd hh:mm", UTC , if fullday is selected hh:mm should be 00:00
    //   endDate: string; // exclusive "yyyy-MM-dd hh:mm", UTC, , if fullday is selected hh:mm should be 00:00
    // }[];
  }[];
};

export type PSetBuildType = { zone: Zone; priceSet: Pricing };

const makeReqPriceFromRange = (cur: PSetBuildType, segment: string) => {
  // only take plain segment price ranges, NOT including any possible surcharges.
  const segmentPriceRanges = cur.priceSet.prices.filter(
    (range) =>
      range.active &&
      range.segment === segment &&
      (!range.groupId ||
        !cur.priceSet.priceGroups.find((pg) => pg.id === range.groupId)
          ?.condition?.surchargeGroup)
  );
  if (segmentPriceRanges.length === 0) return;

  const priceRanges = cur.priceSet.prices.filter(
    (range) =>
      range.active &&
      (range.segment === segment || range.segment === 'surcharge')
  );

  const priceGroups = cur.priceSet.priceGroups.filter((t) => true);
  const priceDiscounts = cur.priceSet.priceDiscounts.filter(
    (t) => t.segment === null || t.segment === segment
  );

  const minute = {
    min: 0,
    max: 0.001,
    ...priceRanges.find(
      (price) =>
        price.duration == 'hour' && price.active && price.segment === segment
    ),
  };
  // the input from prices is an hour price, so we divide by 60 for a minute price to the backend.
  minute.min /= 60;
  minute.max /= 60;

  const daily = {
    min: minute.min * 60 * 24,
    max: minute.max * 60 * 24,
    ...priceRanges.find(
      (price) =>
        price.duration === 'day' && price.active && price.segment === segment
    ),
  };
  const weeklySrc = priceRanges.find(
    (price) =>
      price.duration === 'week' && price.active && price.segment === segment
  );
  const weekly = {
    min: daily.min * 7,
    max: daily.max * 7,
    ...weeklySrc,
  };
  return {
    zoneId: '' + cur.zone.id,
    name: cur.zone.name,
    facilityId: cur.zone.sitePath,
    aggressivity: cur.zone.aggressivity,
    minuteMin: minute.min,
    minuteMax: minute.max,
    dailyMin: daily.min,
    dailyMax: daily.max,
    maxType: 'weekly', //weeklySrc ? 'weekly' : 'daily',
    weeklyMin: weekly.min,
    weeklyMax: weekly.max,
    ranges: priceRanges,
    rangeGroups: priceGroups,
    discounts: priceDiscounts,
  } as Price_Req['pricing'][0];
};

export const buildReq = (
  segments: 'all' | 'driveup' | 'booking',
  start: string,
  end: string,
  pricingSets: PSetBuildType[]
) => {
  const outPricings = pricingSets.reduce(
    (acc, cur) =>
      acc.concat(
        [
          (segments === 'all' || segments === 'driveup'
            ? makeReqPriceFromRange(cur, 'driveup')
            : undefined)!,
          (segments === 'all' || segments === 'booking'
            ? makeReqPriceFromRange(cur, 'booking')
            : undefined)!,
        ].filter((rp) => !!rp)
      ),
    [] as Price_Req['pricing']
  );

  return {
    calcStartDate: start,
    calcEndDate: end,
    pricing: outPricings,
  } as Price_Req;
};

// Fire off an update-request (that is abortable)
export const execUpdate = (
  req: {
    req: string;
    cb: (data: Price_Resp) => void;
    err: () => void;
    reset: () => void;
  } | null,
  updateStatus: (curr: string | null) => void,
  abortFunctionReceiver: (fn: () => void) => void
) => {
  let controller: null | AbortController = new AbortController();
  const { signal } = controller;
  if (abortFunctionReceiver) {
    abortFunctionReceiver(() => {
      console.log('New abort', Math.random());
      if (controller) {
        controller.abort();
        controller = null;
      }
    });
  }
  if (req === null) return;
  updateStatus(req!.req);
  //return;
  if (req) {
    req!.reset();
  }

  fetch('/api/priceui/v3/statscalc', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: req!.req,
    signal,
    // headers: { 'Content-Type:': 'application/json' },
    // credentials: 'include',
  })
    .then((resp) => {
      if (resp.status === 401) {
        // need login
        window.location.href =
          '/user/login?returnUrl=' +
          encodeURIComponent(window.location.pathname.toString());
        return;
      } else if (resp.status !== 200) {
        req!.err();
        alert('Api err:' + resp.statusText);
        throw new Error('Request error,' + resp.statusText);
      }
      return resp.json();
    })
    .then((json) => {
      req!.cb(json as any);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      updateStatus(null);
      // if (pendingReq !== null) {
      //   const arg = pendingReq;
      //   pendingReq = null;
      //   execUpdate(pendingReq, abortFunction);
      // }
    });
};
