import moment from 'moment';
import React from 'react';
import { Bar, Cell, Legend, Line } from 'recharts';
import styled from 'styled-components';
import { GTransformer } from './defs';

import {
  findGraphLevel,
  firstToUpper,
  isSpecialDay,
  roundUpMax,
} from './GraphData';

export const revenueTransformer: GTransformer = (data) => {
  const maxCurrent = data.data.reduce(
    (pre, cur) =>
      Math.max(
        pre,
        cur.segments.reduce((p, c) => Math.max(p, c.defRevenue.current), 0)
      ),
    0
  );
  const maxTotal = data.data.reduce(
    (pre, cur) =>
      Math.max(
        pre,
        Math.max(
          cur.tdAccRev,
          cur.segments.reduce(
            (p, c) => Math.max(p, c.defRevenue.accumulated),
            0
          )
        )
      ),
    0
  );

  const leftMaxViewValue = findGraphLevel(maxCurrent);
  const rightMaxViewValue = findGraphLevel(maxTotal);

  const chartData = (() => {
    return [
      data.bookings && (
        <Bar
          key={'boBar'}
          yAxisId="left"
          dataKey={`segments[${data.segments.indexOf(
            'booking'
          )}].defRevenue.current`}
          name="Booking revenue"
          stackId="a"
          fill="#8bcfce"
        >
          {data.data.map((ent, idx) => {
            const special = isSpecialDay(
              moment.utc(ent.odate).toDate(),
              data
            ).eventExists;

            return (
              <Cell key={ent.odate} fill={special ? '#8cbad1' : '#8bcfce'} />
            );
          })}
        </Bar>
      ),
      data.driveups && (
        <Bar
          key={'duBar'}
          yAxisId="left"
          dataKey={`segments[${data.segments.indexOf(
            'driveup'
          )}].defRevenue.current`}
          name="Driveup revenue"
          stackId="a"
          fill="#66b2b0"
        >
          {data.data.map((ent, idx) => {
            const special = isSpecialDay(
              moment.utc(ent.odate).toDate(),
              data
            ).eventExists;

            let da: any = undefined;

            let fill = '#66b2b0';
            let sc = fill;
            if (special) {
              sc = fill = '#6594b2';
            } else if (ent.isDemandPrediction) {
              fill = '#b5d8d7';
              da = 5;
            }

            return (
              <Cell
                key={ent.odate}
                fill={fill}
                fillOpacity={ent.isDemandPrediction ? 1.0 : undefined}
                stroke={fill}
                // strokeDasharray={da}
                // strokeOpacity={0.7}
                // stroke={sc}
              />
            );
          })}
        </Bar>
      ),
      data.seriesIDs.find((sid) => sid === 'electric_revenue_driveup') && (
        <Bar
          key={'duBarElectric'}
          yAxisId="left"
          dataKey={`series[${data.seriesIDs.findIndex(
            (sid) => sid === 'electric_revenue_driveup'
          )}]`}
          name="Driveup electric revenue"
          stackId={'a'}
          fill="rgb(201, 224, 136)"
          stroke="rgb(201, 224, 136)"
        ></Bar>
      ),
      // data.seriesIDs.find((sid) => sid === 'electric_revenue_driveup') && (
      //   <Line
      //     name={'Driveup acc electric revenue'}
      //     key={'duAccLineElec'}
      //     yAxisId="right"
      //     type="monotone"
      //     dataKey={`seriesAcc[${data.seriesIDs.findIndex(
      //       (sid) => sid === 'electric_revenue_driveup'
      //     )}]`}
      //     dot={{ fill: '#ff00ff' }}
      //     stroke="#ff00ff"
      //     strokeWidth={2}
      //   ></Line>
      // ),

      data.driveups && (
        <Line
          name={`Driveup acc revenue`}
          key={'duAccLine'}
          yAxisId="right"
          type="monotone"
          dataKey={`segments[${data.segments.indexOf(
            'driveup'
          )}].defRevenue.accumulated`}
          stroke="#3e8a88"
          dot={{ fill: '#3e8a88' }}
          strokeWidth={2}
        ></Line>
      ),
      data.bookings && (
        <Line
          key={'bookingAccLine'}
          name={`Booking acc revenue`}
          yAxisId="right"
          type="monotone"
          dataKey={`segments[${data.segments.indexOf(
            'booking'
          )}].defRevenue.accumulated`}
          stroke="#d0e4e3"
          dot={{ fill: '#d0e4e3' }}
          strokeWidth={2}
        ></Line>
      ),

      data.driveups && data.bookings && (
        <Line
          key={'totalAccLine'}
          name={`Total acc revenue`}
          yAxisId="right"
          type="monotone"
          dataKey="tdAccRev"
          stroke="#3c4564"
          dot={{ fill: '#3c4564' }}
          strokeWidth={2}
        ></Line>
      ),
    ];
  })();

  return { chartData, leftMaxViewValue, rightMaxViewValue };
};
