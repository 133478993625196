export const RouteParam = {} as const;

const Route = {
  Facilities: '/facility/:facilityId',
  FacilityRevenue: '/facility/:facilityId/revenue',
  FacilityZRevenue: '/facility/:facilityId/:zoneId/revenue',
  FacilityDemand: '/facility/:facilityId/demand',
  FacilityZDemand: '/facility/:facilityId/:zoneId/demand',
  FacilityPricing: '/facility/:facilityId/pricing',
  FacilityZPricing: '/facility/:facilityId/:zoneId/pricing',
  AdminUsers: '/admin/users',
  AdminDates: '/admin/dates',
  Revenue: '/revenue',
  Demand: '/demand',
  Pricing: '/pricing',
  JTest: '/jtest',
  EVPriceGraph: '/facility/:facilityId/ev/preview',
  EVPriceZGraph: '/facility/:facilityId/:zoneId/ev/preview',
} as const;

export const FacilityRoutes = [
  Route.FacilityZRevenue,
  Route.FacilityRevenue,
  Route.FacilityZDemand,
  Route.FacilityDemand,
  Route.FacilityZPricing,
  Route.FacilityPricing,
  Route.EVPriceGraph,
  Route.EVPriceZGraph,
  Route.Facilities,
];

export default Route;
