export const ZONESTORE = {
  ZONE_APPLY: 'zonestore/apply',
  // EVENT_ADD: 'zonestore/addEvent',
  // EVENT_APPLY: 'zonestore/modEvent',
  ZONE_ADD_PRICE: 'zonestore/ADDPRICE',
  ZONE_MOD_PRICE: 'zonestore/MODPRICE',
  ZONE_REM_PRICE: 'zonestore/REMPRICE',
  ZONE_UPDATE: 'zonestore/ZONE_UPDATE',
} as const;

// export const DEMOZONES = [
//   ['short1', 'Short term'],
//   ['long1', 'Long term one'],
//   ['long2', 'Long term two'],
// ];
