import * as Actions from './action';
import { ActionReturns } from '../../util/tshelpers';

import moment from 'moment';
import { UISTORE } from './constants';
import { durationToMomentDuration } from './uiutil';
export interface UIState {
  activeZoneID: number | string | null;
  activeFacility: string;

  segments: 'all' | 'driveup' | 'booking';

  uiDataUpdateTS: number;

  selectedDate: string; // this is the date the user has picked via fwd/backward

  // Derived fields
  startDate: string; // this one should always be calculated from selectedDate and duration
  endDate: string; // This one should always be calculated from selectedDate and duration
  minStartDate: string; //To keep the user from looking to far into the past
  maxEndDate: string; //To keep the user from looking to far into the future

  duration: 'day' | 'week' | 'month' | 'weeksInYear' | 'monthsInYear';
  //ptype: 'hour' | 'day' | 'week' | 'hour_avg';
  priceTypeViewID: string;
  revview: 'regular' | 'changes' | 'lastYear' | 'lastPeriod' | 'currProp';
  failedToFetch: boolean;
}

const now = moment();

const initialDuration = 'month';
var initialStart = now.clone().utc().startOf(initialDuration);
var endOfNextYear = now.clone().startOf('year').add(2, 'years').add(-1, 'day');
var startOfLastYear = now
  .clone()
  .startOf('year')
  .add(-1, 'year')
  .add(-1, 'day');

export const initialUIState: UIState = {
  segments: 'driveup', //'all',
  //activeZoneID: null,
  activeFacility: '',
  activeZoneID: null,
  selectedDate: now.clone().utc().format('YYYY-MM-DD'),
  startDate: initialStart.format('YYYY-MM-DD'),
  endDate: initialStart.add(1, 'month').format('YYYY-MM-DD'),
  minStartDate: startOfLastYear.format('YYYY-MM-DD'),
  maxEndDate: endOfNextYear.format('YYYY-MM-DD'),
  duration: initialDuration,
  uiDataUpdateTS: 0,
  //ptype: 'hour_avg',
  priceTypeViewID: '',
  revview: 'regular',
  failedToFetch: false,
};

type Actions = ActionReturns<typeof Actions>;

export function reducer(state = initialUIState, action: Actions): UIState {
  switch (action.type) {
    case UISTORE.CHANGE_DURATION: {
      // Changing duration we also want to adjust the date-range and other parameters to fit the user.
      const mdur = durationToMomentDuration(action.duration);
      const startDate = moment(state.selectedDate)
        .utc()
        .startOf(mdur)
        .format('YYYY-MM-DD');
      const endDate = moment(startDate).add(1, mdur).format('YYYY-MM-DD');
      // now put together a new state from all changes.
      return {
        ...state,
        startDate,
        endDate,
        duration: action.duration,
      };
    }
    case UISTORE.DATEMOVE: {
      console.log('Going from date:' + state.startDate);
      const mdur = durationToMomentDuration(state.duration);
      const startDate = moment(state.startDate)
        .add(action.plusMinus, mdur)
        .format('YYYY-MM-DD');
      console.log(startDate);
      // const startDate = _temp.toISOString();
      // const startDate = _temp.add(action.plusMinus, mdur).toISOString(); //Kolla med Jonas för bästa resultat
      console.log('Going to date:' + startDate);
      const endDate = moment(startDate).add(1, mdur).format('YYYY-MM-DD');
      return { ...state, startDate, endDate, selectedDate: startDate };
    }
    case UISTORE.FAILED_TO_FETCH: {
      return { ...state, failedToFetch: true };
    }
    case UISTORE.RESET_FETCH: {
      return { ...state, failedToFetch: false };
    }
    case UISTORE.CHANGE_SEGMENT: {
      return { ...state, segments: action.segment };
    }
    case UISTORE.CHANGE_ACTIVE_ZONE: {
      return { ...state, activeZoneID: action.zoneId };
    }
    case UISTORE.CHANGE_ACTIVE_FACILITY: {
      return {
        ...state,
        activeFacility: action.facilityId,
        segments: action.viewSegment as 'all' | 'booking' | 'driveup',
      };
    }
    case UISTORE.CHANGE_PVIEW_ID: {
      return { ...state, priceTypeViewID: action.id };
    }
    case UISTORE.CHANGE_REVVIEW:
      return { ...state, revview: action.rtype };
    case UISTORE.UPDATE_TS:
      return { ...state, uiDataUpdateTS: action.ts };
  }
  return state;
}
