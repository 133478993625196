import { UISTORE } from './constants';

export const zoneChangeActive = (zoneId: number | string | null) => ({
  type: UISTORE.CHANGE_ACTIVE_ZONE,
  zoneId,
});

export const facilityChangeActive = (
  facilityId: string,
  viewSegment: string
) => ({
  type: UISTORE.CHANGE_ACTIVE_FACILITY,
  facilityId,
  viewSegment,
});

export const facilityChangedComplete = () => ({
  type: UISTORE.CHANGE_ACTIVE_FACILITY_COMPLETE,
});

export const uiChangeDuration = (
  duration: 'day' | 'week' | 'month' | 'weeksInYear' | 'monthsInYear'
) => ({
  type: UISTORE.CHANGE_DURATION,
  duration,
});

export const uiDateMove = (plusMinus: number) => ({
  type: UISTORE.DATEMOVE,
  plusMinus,
});

export const uiChangeSegment = (segment: 'all' | 'driveup' | 'booking') => ({
  type: UISTORE.CHANGE_SEGMENT,
  segment,
});

export const uiChangePViewID = (id: string) => ({
  type: UISTORE.CHANGE_PVIEW_ID,
  id,
});

export const uiChangeRevView = (
  rtype: 'regular' | 'changes' | 'lastPeriod' | 'lastYear' | 'currProp'
) => ({
  type: UISTORE.CHANGE_REVVIEW,
  rtype,
});

export const uiSetDataTS = (ts: number) => ({
  type: UISTORE.UPDATE_TS,
  ts,
});

export const uiResetToFetch = () => ({
  type: UISTORE.RESET_FETCH,
});
export const usSetFailedToFetch = () => ({
  type: UISTORE.FAILED_TO_FETCH,
});
