import React, { FC } from 'react';
import Route from 'constants/Route';
import { NavLink, useRouteMatch, generatePath } from 'react-router-dom';
import styled from 'styled-components';
import ZonePicker from 'containers/ZonePicker';
import { FacilityPageProps } from 'topdefinitions';

export const NavTabWrapper = styled.nav<{
  justifyContent?: 'flex-start' | 'flex-end';
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};

  border-bottom: 1px solid ${({ theme }) => theme.palette.common.lightBorder};
`;

export const navTabActiveClass = 'tabActive';
export const NavTab = styled(NavLink)`
  &,
  &:active,
  &:hover,
  &:visited {
    padding: 13px 25px;
    margin: 0;

    color: inherit;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    border: 1px solid ${({ theme }) => theme.palette.common.lightBorder};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transition: background-color 0.2s;

    &.${navTabActiveClass} {
      font-weight: 700;
      color: inherit;
      box-shadow: inset 0 -3px 0 0 ${({ theme }) => theme.palette.primary.bg};
      border-bottom: 0;
    }
  }

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.palette.grey.bg};
    outline: none;
  }

  &:focus {
    font-weight: 700;
  }
`;

const NavBar: FC<{}> = ({}) => {
  const rm = useRouteMatch() as any as FacilityPageProps['match'];
  const LocRoute = (
    rm.params.zoneId
      ? {
          Revenue: Route.FacilityZRevenue,
          Demand: Route.FacilityZDemand,
          Pricing: Route.FacilityZPricing,
          EVPreview: Route.EVPriceZGraph,
        }
      : {
          Revenue: Route.FacilityRevenue,
          Demand: Route.FacilityDemand,
          Pricing: Route.FacilityPricing,
          EVPreview: Route.EVPriceGraph,
        }
  ) as any;
  for (const k in LocRoute) {
    LocRoute[k] = generatePath(LocRoute[k], rm.params);
  }
  return (
    <NavTabWrapper>
      <NavTab to={LocRoute.Revenue} activeClassName={navTabActiveClass}>
        Revenue
      </NavTab>
      <NavTab to={LocRoute.Demand} activeClassName={navTabActiveClass}>
        Demand
      </NavTab>
      <NavTab to={LocRoute.Pricing} activeClassName={navTabActiveClass}>
        Pricing
      </NavTab>
      <ZonePicker />
    </NavTabWrapper>
  );
};

export default NavBar;
