import React from 'react';
import { useSelector } from 'react-redux';
import { selectUIStateRevView } from 'store/ui/selector';
import GraphBase from './GraphBase';
import { demandTransformer } from './demandTransformer';
import { revenueTransformer } from './revenueTransformer';
import { revenueChangeTransformer } from './revenueChangeTransformer';
import { npricingTransformer } from './npricingTransformer';
import { GTransformer } from './defs';

const Graph: React.FC<{ kind: 'demand' | 'revenue' | 'pricing' }> = ({
  kind,
}) => {
  const revView = useSelector(selectUIStateRevView);
  //console.log(revView);
  return (
    <GraphBase
      kind={kind}
      transformer={
        kind === 'revenue'
          ? revView === 'regular'
            ? revenueTransformer
            : revenueChangeTransformer
          : kind === 'demand'
          ? demandTransformer
          : kind === 'pricing'
          ? npricingTransformer
          : (alert('TODO: transformer=' + kind) as any as GTransformer)
      }
    />
  );
};

export default Graph;
