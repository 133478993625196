import { MiddlewareAPI, Dispatch } from 'redux';

import { calcCacheUpdate, clearCacheUpdate } from '../calcCache/action';
import { CCSTORE } from '../calcCache/constants';
import { Price_Resp } from '../calcCache/definitions';
import { ApplicationState } from '../definitions';
import { EVENTSTOREACTIONS } from '../event/constants';
//import { selectHistoryFacilityMatch } from './router/selector';
import { uiResetToFetch, uiSetDataTS, usSetFailedToFetch } from '../ui/action';
import { UISTORE } from '../ui/constants';
import { Pricing, Zone } from '../zone/reducer';
import {
  selectAvailableFacilityZones,
  selectCurrentZone,
} from '../zone/selector';
import { buildReq, execUpdate } from './zoneUpdateRequestHelpers';

let prevReq = '';
let inFlight = null as string | null;
let controllerAbort: () => void = () => {};
let setController: boolean = false;
//let pendingReq = null as ;

export const zoneUpdatePoll = (
  api: MiddlewareAPI<Dispatch, any>,
  action: any
) => {
  // Post-state we ignore the following messages.
  if (
    action.type === CCSTORE.CLEAR_CACHE ||
    action.type === UISTORE.FAILED_TO_FETCH ||
    action.type === UISTORE.RESET_FETCH ||
    action.type === UISTORE.CHANGE_ACTIVE_FACILITY
  ) {
    return;
  }
  // if the user updates/adds/removes events then we will force an update since that user change has changed the preconditions for what we cache!
  const force = !!Object.keys(EVENTSTOREACTIONS).find(
    (f) =>
      EVENTSTOREACTIONS[f as keyof typeof EVENTSTOREACTIONS] === action.type
  );
  // get the state produced by the previous call to next()
  const newState = api.getState() as ApplicationState;
  //const facilityAndZoneMatch = selectHistoryFacilityMatch(newState);

  //console.log('Updater:', newState, facilityAndZoneMatch);

  // pick the active zone as well as all zones for the current facility.
  const curZone = selectCurrentZone(newState);
  const allZones = selectAvailableFacilityZones(newState);

  //console.log(allZones, newState.uiState.activeFacility);
  // now make a list of the zones for the current request (ie only the picked one or all for the current facility!)
  const reqZones = curZone ? [curZone] : allZones;

  // Make a list of the eventually wanted pricings in the form of a list with zoneId / segment pairs
  const wantedPricings = reqZones.flatMap((rz) =>
    (newState.uiState.segments === 'all'
      ? ['driveup', 'booking']
      : [newState.uiState.segments]
    ).map((segment) => ({
      zoneId: rz.id,
      segment,
    }))
  );

  const pricingSets = reqZones
    // pick out the active pricing (if one exists) for each zone
    .map(
      (curZone) =>
        curZone &&
        curZone.pricings && {
          zone: curZone,
          priceSet: curZone.pricings.find(
            (testPricing) => testPricing.id === curZone.selectedPricing
          )!,
        }
    )
    // remove zones that lack pricings (they shouldn't but let's avoid crashes!)
    .filter((tp) => !!tp.priceSet);

  //console.log('Updater:', curZone, allZones);

  // build a request-object to send to the backend
  const req = buildReq(
    newState.uiState.segments,
    newState.uiState.startDate,
    newState.uiState.endDate,
    pricingSets
  );
  if (req.pricing.length === 0) {
    api.dispatch(clearCacheUpdate());
    api.dispatch(usSetFailedToFetch());
    return; // no relevant pricings.
  }
  // make the completed JSON object
  //console.log('ZU:', req);
  const reqStr = JSON.stringify(req);
  if (
    !force &&
    reqStr === prevReq &&
    action.type !== UISTORE.CHANGE_ACTIVE_FACILITY_COMPLETE
  )
    return;

  // all conditions are right for us to update the request!
  const { startDate, endDate } = newState.uiState;

  //TODO FIX THIS
  if (
    !force &&
    newState.calcCache.cached &&
    newState.calcCache.cached.startDate === startDate &&
    newState.calcCache.cached.endDate === endDate
  ) {
    if (
      newState.calcCache.cached.data &&
      // zoneIdSet check done with wantedPricings to see if we already have the wanted data.
      wantedPricings.reduce(
        (acc, cur) =>
          acc &&
          !!newState.calcCache.cached?.data.zones.find(
            (f) => f.zoneId === cur.zoneId.toString() && f.kind === cur.segment
          ),
        true
      )
      // curZone &&
      // !!newState.calcCache.cached.data.zones.find(
      //   (f) => f.zoneId === curZone.id.toString()
      // )
    ) {
      console.log('GOT DATA ALREADY!', curZone);
      return;
    }
  }

  if (force || inFlight !== reqStr) {
    // timestamp used for UI to know what request response to use.
    const ts = new Date().valueOf();

    const cb = (data: Price_Resp) => {
      api.dispatch(
        calcCacheUpdate({
          cacheTS: ts,
          data,
          zone: curZone!,
          // We calculate a minimal/unique zone id set from the response.
          zoneIdSet: Array.from(
            new Set(data.zones.map((z) => z.zoneId)).values()
          ).sort(),
          startDate,
          endDate,
        })
      );
    };
    const err = () => {
      api.dispatch(usSetFailedToFetch());
    };
    const reset = () => {
      api.dispatch(uiResetToFetch());
    };

    const reqData = { req: reqStr, cb, err, reset };

    controllerAbort();
    execUpdate(
      reqData,
      (cur) => (inFlight = cur),
      (abort) => {
        setController = true;
        controllerAbort = abort;
      }
    );
    api.dispatch(uiSetDataTS(ts));
  }
  prevReq = reqStr;
};
