import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  size: {
    appWidth: 980,
  },
  palette: {
    common: {
      black: '#222831',
      white: '#ffffff',
      orange: '#d4774c',
      darkOverlay: '#2f2e2edb',
      lightBorder: 'rgb(216, 216, 216)',
    },
    text: {
      fg: 'rgb(59, 67, 101)',
      bg: '#ffffff',
    },
    disabled: {
      bg: '#ffffff',
      fg: '#999eb2',
    },
    grey: { bg: 'rgb(240, 240, 240)', fg: 'rgb(59, 67, 101)' },
    primary: {
      bg: 'rgb(137, 87, 160)',
      fg: '#ffffff',
    },
    primaryActive: {
      bg: 'rgb(78, 72, 152)',
      fg: '#ffffff',
    },
    secondary: {
      bg: 'rgb(117, 210, 207)',
      fg: 'rgb(59, 67, 101)',
    },
    secondaryActive: {
      bg: 'rgb(101, 187, 184)',
      fg: 'rgb(59, 67, 101)',
    },
    secondaryLight: {
      bg: '#E6F2F1',
      fg: 'rgb(59, 67, 101)',
    },
    tertiary: {
      bg: 'rgb(247, 163, 121)',
      fg: '#ffffff',
    },
  },
};
