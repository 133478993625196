import { UIState } from './reducer';
export const durationToMomentDuration = (duration: UIState['duration']) => {
  switch (duration) {
    case 'day':
      return 'day';
    case 'week':
      return 'week';
    case 'month':
      return 'month';
    case 'monthsInYear':
    case 'weeksInYear':
      return 'year';
  }
};
