import { Middleware } from 'redux';

import { zoneUpdatePoll } from './global/zoneUpdater';
import { compareUpdatePoll } from './global/compareUpdater';
//import { buildReq, execUpdate } from './calcCache/zoneUpdateRequestHelpers';

// A Redux middleware to be connected for each store ("api")
export const graphDataUpdater_2_0: Middleware = (api) => {
  // per-reducer connection
  return (next) => {
    return (action) => {
      // We just forward all messages to the regular reducers, we're more interested in listening to the post-state.
      next(action); // as ApplicationState;
      zoneUpdatePoll(api, action);
      compareUpdatePoll(api, action);
    };
  };
};
