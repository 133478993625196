import moment from 'moment';
import { Middleware } from 'redux';
import { ApplicationState } from '../../store/definitions';
import { Zone, Pricing, PricingRange } from '../../store/zone/reducer';
import {
  selectAvailableZones,
  selectCurrentFacility,
  selectCurrentZone,
} from '../../store/zone/selector';

import { calcCacheUpdate } from '../../store/calcCache/action';
import { Price_Resp } from '../../store/calcCache/definitions';
import { Event } from '../../store/event/definitions';
import { uiSetDataTS } from '../../store/ui/action';

interface ReqSrcData {
  zones: Zone[];
  events: Event[];
  pricings: Pricing[];
  startDate: string;
  endDate: string;
}

let prevData = {
  //zone: (null as any) as Zone,
  pricings: [] as Pricing[],
  events: [] as Event[],
  startDate: null as any as string,
  endDate: null as any as string,
};
let pendingReqData: {
  req: string;
  cb: (data: Price_Resp) => void;
} | null = null;
let inFlight: string | null = null;

const execUpdate = (req: { req: string; cb: (data: Price_Resp) => void }) => {
  inFlight = req.req;
  fetch('/apdemo/statscalc', {
    body: req.req,
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
  })
    .then((response) => {
      console.log(response);
      if (response.status !== 200) {
        return '';
      }
      return response.json();
    })
    .then((json) => {
      console.log('Response:', json);
      req.cb(json as any);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      inFlight = null;
      if (pendingReqData !== null) {
        const arg = pendingReqData;
        pendingReqData = null;
        execUpdate(arg);
      }
    });
};

// typescript type syntax,
// comments start with //
// primitive values are: number, syntax, boolean
// a union type can have data of multiple types: number|string
// a constant string can be used to indicate a specific value (or combined with unions to describe the set of allowed values)
// an array can contain any other type,
//   an one dimensional array of numbers is written as (  number[]  )
//   or an array of arrays of numbers (multidimensional) is written as (  number[][]  )
// object types are written as: { prop1:prop1type; prop2:prop2type; .... propN:propNtype; }
// an optional property within an object is indicated with a question mark (?) behind the property name.

// A request sent to the matlab process
type Price_Req = {
  calcStartDate: string; // inclusive, "yyyy-MM-dd hh:mm" , UTC, should be specified at midnight
  calcEndDate: string; // exclusive, "yyyy-MM-dd hh:mm" , UTC, should be specified at midnight

  // an array of pricing (one pricing level per zone for the time being)
  pricing: {
    name: string;
    kind: 'driveup' | 'booking' | 'total';
    // zone the price belongs to
    zoneId: number;
    // how many minutes before billing starts
    //minimumBillingMinutes: number;

    // minute min/max pricing levels
    minuteMin: number;
    minuteMax: number;
    aggressivity: number;

    // is there daily or daily and weekly max prices.
    maxType: 'daily' | 'weekly';

    dailyMin: number;
    dailyMax: number;

    weeklyMin?: number;
    weeklyMax?: number;

    // an array of events that modify demand of parking
    events: {
      active: boolean; // is this event activated
      name: string; // user-selected
      kind: string; // loose selection of types such as strike, holiday, conference, sport, closed, repairs, etc
      demand: number; // percentage, 100 as base demand
      fullDays: boolean; // all days within the time-span are included (future UI will switch over to )
      startDate: string; // inclusive "yyyy-MM-dd hh:mm", UTC , if fullday is selected hh:mm should be 00:00
      endDate: string; // exclusive "yyyy-MM-dd hh:mm", UTC, , if fullday is selected hh:mm should be 00:00
    }[];
  }[];
};

const addPricingKind = (
  pricings: Price_Req['pricing'],
  events: Event[],
  zone: Zone,
  inPricings: PricingRange[],
  kind: 'driveup' | 'booking'
) => {
  const prices = inPricings.filter(
    (price) => price.segment === kind && price.active
  );
  if (prices.length === 0) return;

  const minute = {
    min: 0,
    max: 0.001,
    ...prices.find((price) => price.duration === 'hour' && price.active),
  };
  // the input from prices is an hour price, so we divide by 60 for a minute price to the backend.
  minute.min /= 60;
  minute.max /= 60;
  const daily = {
    min: 0,
    max: 0.0001,
    ...prices.find((price) => price.duration === 'day' && price.active),
  };
  const weeklySrc = prices.find(
    (price) => price.duration === 'week' && price.active
  );
  const weekly = {
    min: daily.min * 7,
    max: daily.max * 7,
    ...weeklySrc,
  };
  pricings.push({
    zoneId: zone.id,
    name: zone.name,
    kind,
    aggressivity: zone.aggressivity,
    minuteMin: minute.min,
    minuteMax: minute.max,
    dailyMin: daily.min,
    dailyMax: daily.max,
    maxType: 'weekly', //weeklySrc ? 'weekly' : 'daily',
    weeklyMin: weekly.min,
    weeklyMax: weekly.max,
    events: !events
      ? []
      : events
          .filter(
            (ev) =>
              (ev.routeData
                ? ev.routeData.active
                : ev.zoneData?.some((zd) => zd.active)) &&
              (ev.routeData || ev.zoneData?.some((zd) => zd.zoneID === zone.id))
          )
          .map((ev) => ({
            active:
              (ev.routeData
                ? ev.routeData.active
                : ev.zoneData?.some((zd) => zd.active)) ?? false,
            demand:
              (ev.routeData
                ? ev.routeData.customDemand
                : ev.zoneData?.find((zd) => zd.zoneID == zone.id)
                    ?.customDemand) ?? 0,
            startDate: moment(ev.startDate).format('YYYY-MM-DD hh:mm'),
            endDate: moment(ev.endDate).format('YYYY-MM-DD hh:mm'),
            fullDays: ev.fullDay,
            kind: ev.kind,
            name: ev.name,
          })),
  });
};

const buildRequest = (src: ReqSrcData) => {
  const pricing = [] as Price_Req['pricing'];
  //  const driveUpPricings = ;
  //   const bookingPricings = src.pricing.prices.filter(
  //     (price) => price.segment === 'booking'
  //   );

  for (let srcPricing of src.pricings) {
    addPricingKind(
      pricing,
      src.events,
      src.zones.find((z) => srcPricing.parentZoneId === z.id)!,
      srcPricing.prices,
      'driveup'
    );
    addPricingKind(
      pricing,
      src.events,
      src.zones.find((z) => srcPricing.parentZoneId === z.id)!,
      srcPricing.prices,
      'booking'
    );
  }

  //pricing.push({ hello: 123 });
  return {
    calcStartDate: src.startDate,
    calcEndDate: src.endDate,
    pricing,
  } as Price_Req;
};

export const graphDataUpdater: Middleware = (api) => {
  return (next) => {
    return (action) => {
      next(action); // as ApplicationState;
      const newState = api.getState() as ApplicationState;

      // ensure that we have some relevant state available.
      if (newState && newState.zoneData) {
        //console.log('Updated with zone:' + newState.uiState.activeZoneID);
        const curFacility = selectCurrentFacility(newState);
        const curZone = selectCurrentZone(newState);
        const allZones = selectAvailableZones(newState);
        const allEvents = newState.eventData?.[curFacility?.id as number];
        // pick out the active pricings (either all or for the currently active zone).
        let pricings = (
          curZone
            ? [
                (curZone.pricings &&
                  curZone.pricings.find(
                    (tp) => tp.id === curZone.selectedPricing
                  )) as Pricing,
              ] ?? ([] as Pricing[])
            : allZones.map(
                (cz) =>
                  (cz &&
                    cz.pricings &&
                    cz.pricings.find(
                      (tp) => tp.id === cz.selectedPricing
                    )) as Pricing
              )
        ).filter((tp) => !!tp);

        //console.log('Update allev:', allEvents, prevData.events);

        // Do a deep comparison of the pricing data to see if it's the same (this needs to be simplified or generalized?)
        if (
          //          (
          // Compare that the pricings arrays are of equal length and contains the same pricings.
          prevData.pricings.length !== pricings.length ||
          prevData.pricings
            .map((v, i) => v !== pricings[i])
            .reduce((acc, cur) => acc || cur, false) ||
          prevData.events.length !== allEvents.length ||
          prevData.events
            .map((v, i) => v !== allEvents[i])
            .reduce((acc, cur) => acc || cur, false) ||
          // Also check dates!
          prevData.startDate !== newState.uiState.startDate ||
          prevData.endDate !== newState.uiState.endDate
          // curZone.pricings &&
          // curZone.pricings.find((tp) => tp.id === curZone.selectedPricing)
        ) {
          // building nextData when the change is large enough.
          const nextData: ReqSrcData = {
            startDate: newState.uiState.startDate,
            endDate: newState.uiState.endDate,
            //zone: curZone,
            zones: allZones,
            events: allEvents,
            pricings,
            // pricing: curZone.pricings.find(
            //   (tp) => tp.id === curZone.selectedPricing
            // )!,
          };

          // TODO: fire-off check..
          const req = buildRequest(nextData);
          const reqStr = JSON.stringify(req);

          // timestamp used for UI to know what request response to use.
          const ts = new Date().valueOf();

          // update stored data!
          const cb = (data: Price_Resp) => {
            console.log('Received response:', [data]);
            // update the cached data and mark it with our timestamp
            api.dispatch(
              calcCacheUpdate({
                ...nextData,
                zone: curZone!,
                zoneIdSet: Array.from(
                  new Set(data.zones.map((z) => z.zoneId)).values()
                ).sort(),
                data,
                cacheTS: ts,
              })
            );
            //api.dispatch()
          };

          console.log('Prepped req:', req);

          if (inFlight !== reqStr) {
            if (inFlight !== null) {
              pendingReqData = { req: reqStr, cb };
            } else {
              execUpdate({ req: reqStr, cb });
            }
          }
          prevData = nextData;
          // set the TS of the request so that we show loading until the cached data matches the latest fired off request!
          api.dispatch(uiSetDataTS(ts));
        }
      }

      // we should do a selection here to see if our fetch-view-state has changed.
      //return actionResult;
    };
  };
};
