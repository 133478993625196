import React, { useContext, useState } from 'react';

interface DemandState {
  currentAcc: number;
  oldAcc: number;
  diff: number;
}

// Create a dummy context w/ a nil value and no-op setter action
const demandContext = React.createContext({
  demand: { currentAcc: 0, oldAcc: 0, diff: 0 },
  setAccDemand: ((v: DemandState) => {}) as React.Dispatch<
    React.SetStateAction<DemandState>
  >,
});

export const DemandContextProvider: React.FC = ({ children }) => {
  const [demand, insetAccDemand] = useState({
    currentAcc: 0,
    oldAcc: 0,
    diff: 0,
  });
  const setAccDemand = ((v: DemandState) => {
    if (
      v.currentAcc != demand.currentAcc ||
      v.diff != demand.diff ||
      v.oldAcc != demand.oldAcc
    ) {
      insetAccDemand(v);
    }
  }) as React.Dispatch<React.SetStateAction<DemandState>>;
  return (
    <demandContext.Provider value={{ demand, setAccDemand }}>
      {children}
    </demandContext.Provider>
  );
};

export const useDemandContext = () => useContext(demandContext);
