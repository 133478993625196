import Select from 'components/input/Select';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { zoneChangeActive } from 'store/ui/action';
import { selectAvailableZones, selectCurrentZone } from 'store/zone/selector';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-right: 0;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  padding-right: 5px;
`;

const ZonePicker: FC = () => {
  const dispatch = useDispatch();

  const activeZone = useSelector(selectCurrentZone);
  const zones = useSelector(selectAvailableZones);

  return (
    <Wrapper>
      <Label>Zone:</Label>
      <Select
        value={activeZone == null ? '' : activeZone.id}
        onChange={(evt) => {
          console.log('Select zone:' + evt.target.value);
          dispatch(
            zoneChangeActive(evt.target.value == '' ? null : evt.target.value)
          );
        }}
      >
        <option key={'__ALLZONES'} value={''}>
          All zones
        </option>
        {zones &&
          zones.map((demozone) => {
            return (
              <option key={demozone.id} value={demozone.id}>
                {demozone.name}
              </option>
            );
          })}
      </Select>
    </Wrapper>
  );
};

export default ZonePicker;
