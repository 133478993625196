import NavBar from 'components/NavBar';
import { PriceV3Api } from 'constants/api-fetch';
import moment from 'moment';
import DateNavigation from 'pages/Demand/DateNavigation';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Pricing, Zone, ZoneFlag } from 'store/zone/reducer';
import {
  selectAvailableFacilityZones,
  selectCurrentFacility,
  selectCurrentZone,
} from 'store/zone/selector';
import styled from 'styled-components';
import EVGraph from './graph';
import EVGraphParams from './graphParams';

const GraphTitle = styled.div`
  color: ${({ theme }) => theme.palette.primary.bg};
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;

const GraphSubtitle = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 5px;
  font-size: 12px;
`;

export interface EVData {
  time: string;
  price: number;
  rec: number;
  type: string;
  bcPrice?: number;
}

const Wrapper = styled.div``;

const PriceView: React.FC = () => {
  const allZones = useSelector(selectAvailableFacilityZones);
  const activeZone = useSelector(selectCurrentZone);
  const [date, setDate] = useState<number>(moment().toDate().getTime());
  const [segment, setSegment] = useState<string>('driveup');
  var evZones = allZones.reduce(
    (acc, curr) =>
      curr.zoneFlags?.find((f) => f === ZoneFlag.Electric)
        ? [...acc, curr]
        : acc,
    [] as Zone[]
  );
  const facility = useSelector(selectCurrentFacility);

  const [availableZone, setAvailableZones] = useState<Zone[]>(evZones);
  var selectedZone =
    !!activeZone && activeZone.zoneFlags?.indexOf(ZoneFlag.Electric) !== -1
      ? activeZone
      : undefined;

  const [zone, setZoneId] = useState<Zone | undefined>(selectedZone);
  const [pricingId, setPricingId] = useState<number | undefined>(
    !!selectedZone
      ? selectedZone!.selectedPricing != null
        ? selectedZone.selectedPricing!
        : undefined
      : undefined
  );
  const [initalized, setInitalized] = useState<boolean>(false);

  useEffect(() => {
    fetchEVData();
  }, [date, zone, pricingId]);

  const [priceData, setPriceData] = useState<EVData[]>([]);

  const fetchEVData = async () => {
    var api = new PriceV3Api();
    var data = {
      date,
      timeZoneId: allZones[0].id,
      zoneId: zone?.id,
      segment,
      priceId: pricingId,
      npZoneConfig: facility.config?.electricPricingLocation,
    };
    var url = `facility/evdata`;
    var resp = await api.post(url, JSON.stringify(data));
    setPriceData(resp);
    setInitalized(true);
  };

  var avg = 0;
  var _data = priceData.reduce(
    (acc, curr) => {
      acc.avg += curr.price;
      if (curr.price > acc.maxPricePadding) {
        acc.maxPricePadding = curr.price;
      }
      if (!!curr.bcPrice && curr.bcPrice > acc.maxPricePadding) {
        acc.maxPricePadding = curr.bcPrice;
      }
      return acc;
    },
    {
      avg: 0,
      maxPricePadding: 0,
    }
  );
  var avarage = _data.avg / 24;
  var maxPricePadding = Math.ceil(_data.maxPricePadding) + 2;
  maxPricePadding = maxPricePadding - (maxPricePadding % 2);

  var nextLabel = moment.utc(date).add(1, 'day').format('yyyy-MM-DD');
  if (moment().add(1, 'day') < moment.utc(date).add(1, 'day')) {
    nextLabel = '';
  }

  console.log('SELECTED', selectedZone);
  return (
    <Wrapper>
      <NavBar />
      <EVGraphParams
        zones={evZones}
        onChange={(_zone: Zone) => {
          setZoneId(_zone);
          setPricingId(
            !!_zone
              ? _zone!.selectedPricing != null
                ? _zone.selectedPricing
                : undefined
              : undefined
          );
        }}
        selectedZone={zone?.id}
        selectedPrice={pricingId}
        pricings={!!zone ? zone!.pricings : []}
        onPricingChange={(pricing: Pricing) => {
          setPricingId(pricing.id);
        }}
      />
      <GraphTitle>{moment(date).format('yyyy-MM-DD')}</GraphTitle>
      {!!priceData && priceData.length > 0 && (
        <GraphSubtitle>
          Average Nord Pool price: {avarage.toFixed(2)}
        </GraphSubtitle>
      )}
      <EVGraph
        data={priceData}
        isLoaded={initalized}
        maxPricePadding={maxPricePadding}
      />

      <DateNavigation
        // prevLabel={moment.utc(startDate).add(-1, mdur).format('YY-M-D')}
        // nextLabel={moment.utc(startDate).add(1, mdur).format('YY-M-D')}
        prevLabel={moment.utc(date).add(-1, 'day').format('yyyy-MM-DD')}
        nextLabel={nextLabel}
        onNextClick={() => {
          if (nextLabel === '') {
            return;
          }
          setDate(moment(date).add(1, 'day').toDate().getTime());
        }}
        onPreviousClick={() =>
          setDate(moment(date).add(-1, 'day').toDate().getTime())
        }
      />
    </Wrapper>
  );
};

export default PriceView;
