import moment from 'moment';
import React from 'react';
import { Bar, Cell, Line } from 'recharts';
import { GTransformer } from './defs';

import {
  findGraphLevel,
  firstToUpper,
  isSpecialDay,
  roundUpMax,
} from './GraphData';

export const demandTransformer: GTransformer = (data, zoneSelected) => {
  const maxOcc = data.data.reduce(
    (pre, cur) =>
      Math.max(
        pre,
        cur.segments.reduce((p, c) => Math.max(p, c.demand), 0)
      ),
    0
  );
  const maxPri = data.data.reduce((pre, cur) => Math.max(pre, cur.price), 0);

  const leftMaxViewValue = findGraphLevel(maxOcc);
  const rightMaxViewValue = findGraphLevel(maxPri);

  const chartData = (() => {
    return [
      data.bookings && (
        <Bar
          yAxisId="left"
          dataKey={`segments[${data.segments.indexOf('booking')}].demand`}
          name="Booking Volume"
          stackId="a"
          fill="#E3CBEB"
          key="boDemand"
        >
          {data.data.map((ent, idx) => {
            const special = isSpecialDay(
              moment.utc(ent.odate).toDate(),
              data
            ).eventExists;
            // !data.zone
            //   ? false
            //   : data.zone.events.find((ev) => {
            //       let sd = new Date(ev.startDate);
            //       let ed = new Date(ev.endDate);
            //       let odate = new Date(ent.odate);
            //       if (sd <= odate && odate <= ed) return true;
            //       return false;
            //     });

            return <Cell key={idx} fill={special ? '#CECDF1' : '#E3CBEB'} />;
          })}
        </Bar>
      ),
      data.driveups && (
        <Bar
          yAxisId="left"
          dataKey={`segments[${data.segments.indexOf('driveup')}].demand`}
          name="Driveup Volume"
          stackId="a"
          key="duDemand"
          fill={'#825B9C'}
        >
          {data.data.map((ent, idx) => {
            const special = isSpecialDay(
              moment.utc(ent.odate).toDate(),
              data
            ).eventExists;
            // const special = !data.zone
            //   ? false
            //   : data.zone.events.find((ev) => {
            //       let sd = new Date(ev.startDate);
            //       let ed = new Date(ev.endDate);
            //       let odate = new Date(ent.odate);
            //       if (sd <= odate && odate <= ed) return true;
            //       return false;
            //     });

            let fill = '#825B9C';
            if (special) {
              fill = '#5B67A2';
            } else if (ent.isDemandPrediction) {
              fill = '#dabaf0';
            }

            return <Cell key={idx} fill={fill} />;
          })}
        </Bar>
      ),
      !data.driveups !== !data.bookings && zoneSelected && (
        <Line
          name={firstToUpper(data.priceType + ` prices`)}
          yAxisId="right"
          type="line"
          dataKey="price"
          stroke="#8BD3D0"
          strokeWidth={2}
          dot={{ fill: '#8BD3D0', strokeWidth: 0 }}
          key="price"
        ></Line>
      ),
      // && data.realZoneCount < 2
    ];
  })();

  return { chartData, leftMaxViewValue, rightMaxViewValue };
};
