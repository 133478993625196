export const UISTORE = {
  CHANGE_ACTIVE_ZONE: 'uistore/CHANGE_ACTIVE_ZONE',
  DATEMOVE: 'uistore/DATEMOVE',
  CHANGE_DURATION: 'uistore/CHANGE_DURATION',
  CHANGE_SEGMENT: 'uistore/CHANGE_SEGMENT',
  CHANGE_PTYPE: 'uistore/CHANGE_PTYPE',
  CHANGE_PVIEW_ID: 'uistore/CHANGE_PVIEW_ID',
  CHANGE_REVVIEW: 'uistore/CHANGE_REVVIEW',
  UPDATE_TS: 'uistore/UPDATE_TS',
  CHANGE_ACTIVE_FACILITY: 'uistore/CHANGE_ACTIVE_FACILITY',
  CHANGE_ACTIVE_FACILITY_COMPLETE: 'uistore/CHANGE_ACTIVE_FACILITY_COMPLETE',
  FAILED_TO_FETCH: 'uistore/FAILED_TO_FETCH',
  RESET_FETCH: 'uistore/RESET_FETCH',
} as const;
