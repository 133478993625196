import usePricingEditorReducer from 'containers/PriceProductEditor/usePricingEditorState';
import { Facilities } from 'pages/admin/Users/userAdminContext';
import { Facility } from 'store/uiSettings/reducer';
import { PricingGroup, PricingRange, Zone } from 'store/zone/reducer';
import DefaultsJson from './AIDefaults.json';
import jsonFiles from './json-files';
import JsonFile from './json-files';

export enum GeneralStrategy {
  volume = 'volume',
  balanced = 'balanced',
  maximize = 'maximize',
}

////////// Legacy, new impl at end of file //////////////////////

const personalityTranslation = (personality: string) => {
  switch (personality) {
    case 'Conservative':
      return GeneralStrategy.volume;
    case 'Middle':
      return GeneralStrategy.balanced;
    case 'High':
      return GeneralStrategy.maximize;
    default:
      throw Error('Unknown personality: ' + personality);
  }
};

// Classic AI default tables hold this form.
// Has no notion of flexible ranges
export interface AIDefault {
  CliZoneId: number;
  ZoneName: string;
  Kind: 'Driveup' | 'Booking';
  generalStrategy: GeneralStrategy;
  personality: string;
  MinuteMin?: number;
  MinuteMax?: number;
  DayMin?: number;
  DayMax?: number;
  WeekMin?: number;
  WeekMax?: number;
  extraData?: AIExtraData[];
}

// hack to add range support-ish
interface AIExtraData {
  name: string;
  duration: PricingRange['duration'];
  min: number;
  max: number;
  groupId: number;
}

interface JsonData {
  Duration: string;
  Min: number;
  Max: number;
  ExtendedStay?: boolean;
}

const parseNum = (numStr: string) => {
  const num = Number(numStr);
  if (isNaN(num)) {
    return undefined;
  }
  return num;
};

const AIDefaults = DefaultsJson.map(
  (d): AIDefault => ({
    CliZoneId: d.CliZoneId,
    ZoneName: d.ZoneName,
    Kind: d.Kind as any,
    generalStrategy: personalityTranslation(d.Personality),
    personality: d.Personality,
    DayMax: parseNum(d.DayMax),
    DayMin: parseNum(d.DayMin),
    MinuteMax: parseNum(d.MinuteMax),
    MinuteMin: parseNum(d.MinuteMin),
  })
);

export default AIDefaults;

interface FaciilityHandling {
  file: any;
  nameParsing: (zone: Zone, segment: string) => string;
  translateExtra: (
    jsonData: JsonData,
    segment: string,
    priceProductEditorReducer: ReturnType<typeof usePricingEditorReducer>
  ) => AIExtraData[];
}

const FacilityJsonHandling = {
  18: {
    file: JsonFile.OsloAiPricing,
    nameParsing: (zone: Zone, segment: string) => {
      var zoneIdentifer = zone.name.split(' ')[1];
      return `${zoneIdentifer}_${segment}`;
    },
    translateExtra: (
      jsonData: JsonData,
      segment: string,
      priceProductEditorReducer: ReturnType<typeof usePricingEditorReducer>
    ) => {
      // var name = '';
      // switch (jsonData.Duration) {
      //   case 'day10':
      //     name = '10 days+';
      //     break;
      //   default:
      // }
      // if (name === '') {
      //   return [];
      // }
      // var priceGroup = editState.priceGroups.find((f) =>  f.name === name);
      const [editState, actions] = priceProductEditorReducer;

      var priceGroups = editState.priceGroups.filter((f) => {
        if (
          jsonData.ExtendedStay &&
          f.condition &&
          f.condition.durationDayRange &&
          f.condition.durationDayRange[0] > 0
        )
          return true;
        return false;
      });
      var aiExtraData: AIExtraData[] = [];
      if (!priceGroups || !priceGroups.length)
        return aiExtraData; // empty-extra data if we cannot find an matching group
      else {
        var pricings = editState.prices.filter((f) =>
          priceGroups.find((pg) => pg.id === f.groupId && f.segment === segment)
        );
        pricings.forEach((price) => {
          var extra: AIExtraData = {
            duration: price.duration,
            min: Math.round(jsonData.Min),
            max: Math.round(jsonData.Max),
            groupId: price.groupId!,
            name: jsonData.Duration,
          };
          aiExtraData.push(extra);
        });
      }
      return aiExtraData;
    },
  },
} as { [key: number]: FaciilityHandling };

const GeneratedAiDefaultStrategy = {
  [GeneralStrategy.balanced]: 'optimal_pricing',
  [GeneralStrategy.volume]: 'customer_focused_pricing',
  [GeneralStrategy.maximize]: 'revenue_focused_pricing',
};

export const generateAiDefault = (
  facility: Facility,
  zone: Zone,
  generalStrategy: GeneralStrategy,
  priceProductEditorReducer: ReturnType<typeof usePricingEditorReducer>
) => {
  var facilityHandling = FacilityJsonHandling[facility.id];
  if (!facilityHandling.file) {
    return [];
  }
  var strategy = GeneratedAiDefaultStrategy[generalStrategy];
  // console.log(PricingRange)
  var aiDefaults: AIDefault[] = facility.segments.reduce(
    (acc, currentSegment) => {
      var name = facilityHandling.nameParsing(zone, currentSegment);
      var jsonData = facilityHandling.file[name][strategy] as JsonData[];
      if (!jsonData) {
        return acc;
      }

      var aiDefault: AIDefault = {
        CliZoneId: zone.id,
        ZoneName: zone.name,
        Kind: (currentSegment[0].toUpperCase() +
          currentSegment.substring(1)) as AIDefault['Kind'],
        generalStrategy: generalStrategy,
        personality: generalStrategy.toString(),
        extraData: [],
      };

      jsonData.forEach((pricing) => {
        if (pricing.ExtendedStay) {
          var extraData = facilityHandling.translateExtra(
            pricing,
            currentSegment,
            priceProductEditorReducer
          );
          aiDefault.extraData = [...aiDefault.extraData!, ...extraData];
          return;
        }
        switch (pricing.Duration) {
          case 'hour':
            aiDefault.MinuteMin = pricing.Min / 60;
            aiDefault.MinuteMax = pricing.Max / 60;
            break;
          case 'day':
            aiDefault.DayMin = Math.round(pricing.Min);
            aiDefault.DayMax = Math.round(pricing.Max);
            break;
          case 'week':
            aiDefault.WeekMin = Math.round(pricing.Min);
            aiDefault.WeekMax = Math.round(pricing.Max);
            break;
          default:
            throw new Error('Unknown duration:' + pricing.Duration);
        }
      });
      return [...acc, aiDefault];
    },
    [] as AIDefault[]
  );
  return aiDefaults;
};

////////////////////////// NEW CODE! //////////////

// Some zones are to be priced like other zones, so we have an alias list to redirect those.

const zoneIdAliases = [
  { z: 31, as: 29 }, // P10 elec as P10
  { z: 32, as: 30 }, // P11 elec as P11
  { z: 34, as: 33 }, // P6 aliased as P4
  { z: 35, as: 37 }, // P1 as P2
  { z: 36, as: 29 }, // P10 PG as P10
  { z: 38, as: 37 }, // P3 as P2
  { z: 39, as: 33 }, // P5 aliased as P4
  { z: 40, as: 33 }, // P7 aliased as P4
] as {
  z: number;
  as: number;
}[];

// This method returns an applicator if there is zone-suggestions for that zone.
export function tryGetAIDefaultApplicator(
  zoneId: string | number,
  pricing: GeneralStrategy
) {
  // First check if we do have an alias? (Maybe zones are compounds so one suggestion covers multiple)
  const aliasInfo = zoneIdAliases.find((t) => t.z === zoneId);
  // If so, use the aliased zoneId
  zoneId = aliasInfo?.as ?? zoneId;

  // Filter out flatly those suggestions that are relevant
  const suggestionSet = Object.keys(jsonFiles)
    .filter((k) => k.startsWith('v2_'))
    .flatMap(
      (k) =>
        (jsonFiles as any)[k] as {
          key: string;
          ranges: {
            CliZoneId: number;
            Kind: 'booking' | 'driveup';
            ptype: GeneralStrategy;
            Duration: string;
            Min: number;
            Max: number;
            ExtendedStay: boolean;
          }[];
        }[]
    )
    .flatMap((se) => se.ranges)
    .filter((se) => se.CliZoneId === zoneId && se.ptype === pricing);

  if (suggestionSet.length === 0) {
    console.log('New impl set lacks the needed info for zone:' + zoneId);
    return null;
  }

  console.log(`Suggestion set for ${zoneId} / ${pricing}`, suggestionSet);

  // return an applicator function that will match individual ranges to the pertinent suggestion.
  return (pr: PricingRange, pgs: PricingGroup[]) => {
    console.log('Updating individual price ' + pr);

    // If the range has a group, pick it out first
    const pg = pgs.find((tpg) => tpg.id === pr.groupId);

    // Now locate the suggestion for this range.
    const suggestion = suggestionSet.find((se) => {
      return (
        se.CliZoneId === zoneId &&
        se.Kind === pr.segment &&
        se.Duration === pr.duration &&
        ((!se.ExtendedStay && pr.groupId === null) ||
          // Right now we only support ExtendedStay as an special flag
          (!!se.ExtendedStay &&
            pg &&
            !pg.condition.surchargeGroup &&
            pg.condition.durationDayRange &&
            pg.condition.durationDayRange[0] !== 0))
      );
    });
    // If an suggestion matched, apply it.
    if (suggestion) {
      return {
        ...pr,
        min: Math.round(suggestion.Min),
        max: Math.round(suggestion.Max),
      };
    }
    // Otherwise retain the original value.
    return pr;
  };
}
