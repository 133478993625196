import React, { FC } from 'react';
import Label from 'components/input/Label';
import Select from 'components/input/Select';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  uiChangeDuration,
  uiChangePViewID,
  uiChangeRevView,
  uiChangeSegment,
} from 'store/ui/action';
import {
  selectUiStateDuration,
  selectUiStateEndDate,
  selectUiStatePriceTypeViewID,
  selectUIStateRevView,
  selectUiStateSegment,
  selectUiStateStartDate,
  selectUIValidPriceTypes,
} from 'store/ui/selector';
import moment, { duration } from 'moment';
import { selectUISettingZones } from 'store/uiSettings/selectors';
import { selectCurrentFacility, selectCurrentZone } from 'store/zone/selector';

const segmentInputClass = 'segmentInput';
const periodInputClass = 'periodInput';
const pricesInputClass = 'pricesInput';

const fromDateClass = 'fromDate';
const toDateClass = 'toDate';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns:
    minmax(190px, 1fr) minmax(190px, 1fr) minmax(190px, 1fr)
    3fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  margin: 20px 0;
  flex: 1;

  & > * {
    min-width: 140px;
  }

  .${segmentInputClass} {
    grid-area: 1/1/2/2;
  }
  .${pricesInputClass} {
    grid-area: 1/3/2/4;
  }
  .${periodInputClass} {
    grid-area: 1/2/2/3;
  }

  .${fromDateClass} {
    grid-area: 1/5/2/6;
  }
  .${toDateClass} {
    grid-area: 1/6/2/7;
  }
`;

interface Props {
  hidePrices?: boolean;
  showRevenue?: boolean;
}
interface PriceType {
  value: string;
  name: string;
}

const GraphParams: FC<Props> = ({ hidePrices, showRevenue }) => {
  const dispatch = useDispatch();

  const curDuration = useSelector(selectUiStateDuration);
  const curSegment = useSelector(selectUiStateSegment);
  const curZone = useSelector(selectCurrentZone);

  const curPriceViewTypeID = useSelector(selectUiStatePriceTypeViewID);
  const startDate = useSelector(selectUiStateStartDate);
  const endDate = useSelector(selectUiStateEndDate);
  const revView = useSelector(selectUIStateRevView);
  const facility = useSelector(selectCurrentFacility);
  const zones = useSelector(selectUISettingZones);

  const cachedPriceSeries = useSelector(selectUIValidPriceTypes);

  const supportedPrices: PriceType[] = [
    {
      value: 'hour',
      name: 'Hour',
    },
    {
      value: 'hour_avg',
      name: 'Hour average',
    },
    {
      value: 'day',
      name: 'Day',
    },
    {
      value: 'week',
      name: 'Week',
    },
  ];
  let availablePriceTypes: PriceType[] = [];
  const selectedZoneSettings = zones.find(
    (zone) => zone.zoneId === curZone?.id
  );
  if (selectedZoneSettings !== undefined) {
    supportedPrices.forEach((priceType) => {
      const availableType = selectedZoneSettings.enabledPriceTypes.find(
        (obj) => obj === priceType.value
      );
      if (availableType !== undefined) {
        availablePriceTypes.push(priceType);
      }
    });
  }

  //Filtering prices dropdown based of Time period
  const pricesList = availablePriceTypes.filter((f) => {
    switch (curDuration) {
      case 'day':
        return f.value === 'hour';
      //case 'week':
      //  return f.value !== 'week';
      case 'month':
      case 'weeksInYear':
        return f.value !== 'hour';
      default:
        return f;
    }
  });

  // pick a valid value from the list, otherwise default to a hour view.

  return (
    <Wrapper>
      <Label label="Segment" className={segmentInputClass}>
        <Select
          value={curSegment}
          onChange={(evt) => {
            //if (evt.target.value === 'booking') return;
            dispatch(uiChangeSegment(evt.target.value as any));
          }}
        >
          {facility.segments.length > 1 ? (
            <option value="all">All</option>
          ) : null}
          {facility.segments.indexOf('driveup') != -1 ? (
            <option value="driveup">Driveup</option>
          ) : null}
          {facility.segments.indexOf('booking') != -1 ? (
            <option value="booking">Booking</option>
          ) : null}
        </Select>
      </Label>

      <Label label="Time period" className={periodInputClass}>
        <Select
          onChange={(evt) => {
            const value = evt.target.value as string;
            dispatch(uiChangeDuration(value as any));
          }}
          value={curDuration}
        >
          <option value="day">Day</option>
          <option value="week">Week</option>
          <option value="month"> Month</option>
          <option value="weeksInYear">Year</option>
        </Select>
      </Label>

      {!hidePrices && availablePriceTypes.length > 0 && (
        <Label label="Prices" className={pricesInputClass}>
          <Select
            onChange={(evt) => {
              dispatch(uiChangePViewID(evt.target.value as any));
            }}
            value={curPriceViewTypeID}
          >
            {cachedPriceSeries.map((cps) => (
              <option key={cps.id} value={cps.id}>
                {cps.name} {cps.isAverage ? ' average' : ''}
              </option>
            ))}
          </Select>
        </Label>
      )}
      {showRevenue && (
        <Label label="Compare">
          <Select
            value={revView}
            onChange={(evt) => {
              dispatch(uiChangeRevView(evt.target.value as any));
            }}
          >
            <option value="regular">None</option>
            <option value="lastYear">Last year</option>
            <option value="currProp">Dynamic difference</option>
            {/* <option value="regular">None</option>
            <option value="changes">Last year</option>
            <option value="lastPeriod">Last period</option> */}
          </Select>
        </Label>
      )}
      <Label label="Showing From" className={fromDateClass}>
        {moment(startDate).format('yyyy-MM-DD')}
      </Label>
      <Label label="Showing To" className={toDateClass}>
        {moment(endDate).format('yyyy-MM-DD')}
      </Label>
    </Wrapper>
  );
};

export default GraphParams;
