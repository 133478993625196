import Select from 'components/input/Select';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch, generatePath } from 'react-router';
import { facilityChangeActive, zoneChangeActive } from 'store/ui/action';
import {
  selectUISettingSites,
  selectUISettingZones,
} from 'store/uiSettings/selectors';
import {
  selectAvailableZones,
  selectCurrentFacility,
  selectCurrentZone,
} from 'store/zone/selector';
import styled from 'styled-components';
import Routes from '../constants/Route';
import APDemoZonePicker from './ZonePicker_ApDemo';
import { FacilityPageProps } from '../topdefinitions';
import { selectUiStateSegment } from 'store/ui/selector';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-right: 0;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  padding-right: 5px;
`;

export const destinationsData = {
  '/revenue': [Routes.FacilityRevenue, Routes.FacilityZRevenue],
  '/demand': [Routes.FacilityDemand, Routes.FacilityZDemand],
  '/pricing': [Routes.FacilityPricing, Routes.FacilityZPricing],
  '/': [Routes.FacilityRevenue, Routes.FacilityZRevenue],
} as { [k: string]: string[] };
export const destinations = Object.keys(destinationsData).map((k) => ({
  dest: k,
  path: destinationsData[k],
}));

const ZonePicker: FC = () => {
  const dispatch = useDispatch();

  const activeZone = useSelector(selectCurrentZone);
  const zones = useSelector(selectAvailableZones);

  // pick out unique facility routes.
  const facilityRoutes = zones
    .map((zn) => zn.sitePath ?? '')
    .reduce(
      (acc, cur) =>
        acc.find((existing) => existing === cur) ? acc : [...acc, cur],
      [] as string[]
    );
  const isApDemo =
    facilityRoutes.length == 0 ||
    (facilityRoutes.length == 1 && facilityRoutes[0] === '');

  const uiSetSites = useSelector(selectUISettingSites);
  const uiSetZones = useSelector(selectUISettingZones);

  const curSegment = useSelector(selectUiStateSegment);

  const history = useHistory();
  const match = useRouteMatch<FacilityPageProps['match']['params']>();

  // Fall back to the old zonepicker for the APDemo
  if (isApDemo) return <APDemoZonePicker />;

  const value =
    match?.params?.zoneId !== undefined
      ? match?.params?.zoneId
      : 'site:' + match?.params?.facilityId;

  const facilityId = match?.params?.facilityId;

  const facilityZones = uiSetSites.filter((f) => f.path == facilityId);
  return (
    <Wrapper>
      <Label>Zone:</Label>
      <Select
        value={value}
        onChange={(evt) => {
          // pick the longest matching path-type
          const routeType = destinations.reduce(
            (prev, cur) =>
              match?.path.indexOf(cur.dest) === -1
                ? prev
                : prev.dest.length < cur.dest.length
                ? cur
                : prev,
            { dest: '/', path: destinationsData['/'] }
          );
          let value = evt.target.value;
          //console.warn('Untested path for ALL segments');
          if ('string' === typeof value && value.startsWith('site:')) {
            history.push(
              generatePath(routeType.path[0], {
                facilityId: value.substring(5),
              })
            );
            dispatch(zoneChangeActive(null));
            dispatch(facilityChangeActive(value.substring(5), curSegment));
          } else {
            const zone = uiSetZones.find(
              (tz) => tz.zoneId.toString() === evt.target.value.toString()
            );
            history.push(
              generatePath(routeType.path[1], {
                facilityId: zone?.routePath,
                zoneId: zone?.zoneId,
              })
            );
            dispatch(facilityChangeActive(zone!.routePath, curSegment));
            dispatch(zoneChangeActive(zone!.zoneId));
          }
        }}
      >
        {facilityZones.map((site) => {
          return (
            <React.Fragment key={site.id}>
              <option value={'site:' + site.path}>All zones</option>
              {zones
                .filter((zone) => zone.sitePath === site.path)
                .map((zone) => (
                  <option key={zone.id} value={zone.id}>
                    {zone.name}
                  </option>
                ))}
            </React.Fragment>
          );
        })}

        {/* <option key={'__ALLZONES'} value={''}>
          All zones
        </option>
        {zones &&
          zones.map((demozone) => {
            return (
              <option key={demozone.id} value={demozone.id}>
                {demozone.name}
              </option>
            );
          })} */}
      </Select>
    </Wrapper>
  );
};

export default ZonePicker;
