import Modal from '../../components/Modal';
import PriceProductEditor from '../../containers/PriceProductEditor';
import usePricingEditorReducer from '../../containers/PriceProductEditor/usePricingEditorState';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pricing, PricingRange, Zone } from '../../store/zone/reducer';
import { selectCurrentZone } from '../../store/zone/selector';
import { ApplicationState } from '../../store/definitions';
import { PriceV3Api } from 'constants/api-fetch';
import { clearCacheUpdate } from 'store/calcCache/action';

const makePricingRange = (
  id: number,
  duration: PricingRange['duration'],
  segment: PricingRange['segment']
): PricingRange => ({
  id,
  active: false,
  toggleable: true,
  duration,
  max: 0,
  min: 0,
  segment,
});

interface Props {
  priceId: number;
  zone?: Zone;
  onModPricing(pricing: Pricing): void;
}

const ensurePrices = (prices: PricingRange[]) => {
  const reqds = [
    { dur: 'day', seg: 'driveup' },
    { dur: 'hour', seg: 'driveup' },
    { dur: 'week', seg: 'driveup' },
    { dur: 'day', seg: 'booking' },
    { dur: 'hour', seg: 'booking' },
    { dur: 'week', seg: 'booking' },
  ] as const;
  for (let i = 0; i < reqds.length; i++) {
    const req = reqds[i];
    if (prices.find((pr) => pr.duration === req.dur && pr.segment === req.seg))
      continue;
    prices = [...prices, makePricingRange(-1 - i, req.dur, req.seg)];
  }
  return prices;
};

const ModPriceProductModal: FC<Props> = ({ priceId, zone, onModPricing }) => {
  // assumes that this modal can't be opened if a zone isn't selected
  let curZone = useSelector(selectCurrentZone);
  let _zone = zone;
  if (!_zone) {
    _zone = curZone;
  }

  const inputPricing = _zone!.pricings.find((zp) => zp.id === priceId)!;

  const priceProductEditorReducer = usePricingEditorReducer(
    { ...inputPricing }
    /*
    {
    name: '',
    aggressivity: 0,
    id: '',
    parentZoneId: zone.id,
    prices: [
      makePricingRange('-1', 'day', 'driveup'),
      makePricingRange('-2', 'hour', 'driveup'),
      makePricingRange('-3', 'week', 'driveup'),
      makePricingRange('-4', 'day', 'booking'),
      makePricingRange('-5', 'hour', 'booking'),
      makePricingRange('-6', 'week', 'booking'),
    ],
  }*/
  );

  const dispatch = useDispatch();

  return (
    <Modal
      title="Change price product"
      buttons={[
        {
          disabled: !(
            priceProductEditorReducer[0].name.length > 0 &&
            priceProductEditorReducer[0].prices.find(
              (pricing) => pricing.active
            )
          ),
          label: 'Change',
          onClick: () => {
            var api = new PriceV3Api();
            api
              .post(
                'update/pricing',
                JSON.stringify(priceProductEditorReducer[0])
              )
              .then((pricing: Pricing) => {
                if (pricing.selected) {
                  dispatch(clearCacheUpdate());
                }
                onModPricing(pricing);
              });
          },
        },
      ]}
    >
      <PriceProductEditor
        priceProductEditorReducer={priceProductEditorReducer}
      />
    </Modal>
  );
};

export default ModPriceProductModal;
